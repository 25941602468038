import Colors from "../../../Cargo/Colors";
import Dropdown from "../../../Cargo/Controls/Dropdown";
import { dropdownHeader } from "../../../Cargo/Controls/dropdownHeader";
import { DropdownElement } from "../../../Cargo/Controls/DropdownElement";
import CreditCardLogo from "../../../Cargo/Icons/CreditCardLogo";
import FirstToMatch from "../../../Cargo/Layout/FirstToMatch";
import { FirstToMatchEnum } from "../../../Cargo/Layout/FirstToMatchEnum";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import {
  BankAccount,
  CreditCard,
} from "@freightsimple/generated-dashboard-openapi-client";
import moment from "moment";
import { ReactElement } from "react";
import styled from "styled-components";

const CardNumber = styled.div`
  font-size: 16px;
  font-weight: var(--nhu-font-weight-regular);
`;

const CardExpiry = styled.div`
  font-size: 16px;
  font-weight: var(--nhu-font-weight-light);
  color: ${Colors.LightText};

  .selected-dropdown-row & {
    color: white;
  }
`;

const CardExpired = styled.div`
  font-size: 16px;
  font-weight: var(--nhu-font-weight-light);
  color: ${Colors.Red};

  .selected-dropdown-row & {
    color: white;
  }
`;

interface PaymentMethodsDropdownProps {
  cards: Array<CreditCard>;
  bankAccounts: Array<BankAccount>;
  isChequePaymentPermitted: boolean;
  isEFTPaymentPermitted: boolean;
  isETransferPaymentPermitted: boolean;
  isWirePaymentPermitted: boolean;
  selectedStripePaymentMethodId: string | undefined;
  onOptionSelected: (_: string) => void;
}

function creditCardDropdownOptions(cards: CreditCard[]) {
  return cards.map((cardInfo) => {
    // Year 3000 bug for the win ;)
    const expiryMonth = parseInt(cardInfo.expiry.split("/")[0]);
    const expiryYear = 2000 + parseInt(cardInfo.expiry.split("/")[1]);
    const now = moment();
    const currentYear = now.year();
    const currentMonth = now.month() + 1; // moment is 0-indexed

    function expired() {
      if (currentYear > expiryYear) {
        return true;
      }

      if (currentYear === expiryYear && currentMonth > expiryMonth) {
        return true;
      }

      return false;
    }

    function aboutToExpire() {
      if (currentYear === expiryYear && currentMonth === expiryMonth) {
        return true;
      }

      return false;
    }

    return {
      item: cardInfo.stripePaymentMethodId,
      description: (
        <DropdownElement>
          <HorizontalStack width={"480px"} verticalAlign="top">
            <CreditCardLogo size="regular" brand={cardInfo.brand} />
            <Spacer width={8} />
            <CardNumber>XXXX XXXX XXXX {cardInfo.lastFourDigits}</CardNumber>
            <Spacer width={8} />
            <FirstToMatch
              components={[
                {
                  when: aboutToExpire(),
                  show: (
                    <CardExpired>
                      Too close to expiry: {cardInfo.expiry}
                    </CardExpired>
                  ),
                },
                {
                  when: expired(),
                  show: <CardExpired>Expired: {cardInfo.expiry}</CardExpired>,
                },
                {
                  when: FirstToMatchEnum.Default,
                  show: <CardExpiry>Expiry: {cardInfo.expiry}</CardExpiry>,
                },
              ]}
            />
          </HorizontalStack>
        </DropdownElement>
      ),
    };
  });
}

function bankAccountDropdownOptions(bankAccounts: BankAccount[]) {
  return bankAccounts.map((bankAccount) => {
    return {
      item: bankAccount.stripePaymentMethodId,
      description: (
        <DropdownElement>
          <HorizontalStack width={"480px"} verticalAlign="top">
            <CreditCardLogo
              size="regular"
              brand={bankAccount.institutionNumber}
            />
            <Spacer width={8} />
            <CardNumber>Account Ending {bankAccount.lastFourDigits}</CardNumber>
          </HorizontalStack>
        </DropdownElement>
      ),
    };
  });
}

function otherDropdownOption(id: string, label: string) {
  return {
    item: id,
    description: (
      <DropdownElement>
        <HorizontalStack
          style={{ height: "26px" }}
          width={"480px"}
          verticalAlign="top"
        >
          <CardNumber>{label}</CardNumber>
        </HorizontalStack>
      </DropdownElement>
    ),
  };
}

export function PaymentMethodsDropdown(
  props: PaymentMethodsDropdownProps,
): ReactElement {
  const options = [];

  if (props.cards.length > 0) {
    options.push(dropdownHeader("Credit Cards"));
    creditCardDropdownOptions(props.cards).forEach(function (o) {
      options.push(o);
    });
  }

  if (props.bankAccounts.length > 0) {
    options.push(dropdownHeader("Bank Accounts"));
    bankAccountDropdownOptions(props.bankAccounts).forEach(function (o) {
      options.push(o);
    });
  }

  if (
    props.isChequePaymentPermitted ||
    props.isEFTPaymentPermitted ||
    props.isETransferPaymentPermitted ||
    props.isWirePaymentPermitted
  ) {
    options.push(dropdownHeader("Other Methods"));
  }

  if (props.isChequePaymentPermitted) {
    options.push(otherDropdownOption("cheque", "Pay by Cheque"));
  }

  if (props.isEFTPaymentPermitted) {
    options.push(otherDropdownOption("eft", "Pay by EFT"));
  }

  if (props.isETransferPaymentPermitted) {
    options.push(otherDropdownOption("etransfer", "Pay by Interac eTransfer"));
  }

  if (props.isWirePaymentPermitted) {
    options.push(otherDropdownOption("wire", "Pay by Wire Transfer"));
  }

  return (
    <Dropdown
      unselectedDescription="Select a payment method"
      selectedItem={props.selectedStripePaymentMethodId}
      onOptionSelected={props.onOptionSelected}
      options={options}
      errorMessage={undefined}
      width={500}
      menuWidth={500}
    />
  );
}
