import { useShipmentsApi } from "../../../apis";
import Button from "../../../Cargo/Controls/Button";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import useAlertModal from "../../../Cargo/Modal/useAlertModal";
import { Heading1, Legalese } from "../../../Cargo/Text/Text";
import { PreBookingShipment } from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";
import { ChangePickupDateControl } from "../Components/ChangePickupDateControl";

interface ChangePickupDateModalProps {
  onDone: (newPickupDate: string | undefined) => void;
  shipment: PreBookingShipment;
}

export function ChangePickupDatePreBookingModal(
  props: ChangePickupDateModalProps,
) {
  const showAlert = useAlertModal();
  const [processing, setProcessing] = useState(false);

  const [date, setDate] = useState(props.shipment.pickupDate);
  const shipmentsApi = useShipmentsApi();

  async function onConfirm() {
    setProcessing(true);
    try {
      await shipmentsApi.changePickupDatePreBooking({
        changePickupDatePreBookingRequest: {
          shipmentId: props.shipment.shipmentId,
          pickupDate: date,
        },
      });
      setProcessing(false);
      props.onDone(date);
    } catch {
      setProcessing(false);
      await showAlert(
        "Error",
        "Sorry, something went wrong. Our team has been alerted.",
      );
    }
  }

  return (
    <Stack align="left" style={{ padding: "16px", width: "800px" }}>
      <Spacer height={16} />
      <Heading1>Change Pickup Date</Heading1>
      <Legalese>
        Select the updated date when your shipment will be ready for pickup.
      </Legalese>
      <Spacer height={32} />
      <ChangePickupDateControl
        country={props.shipment.pickupLocation.address.countryCode}
        currentPickupDate={props.shipment.pickupDate}
        date={date}
        onDateChange={function (m) {
          setDate(m.format("YYYY-MM-DD"));
        }}
      />

      <Spacer height={64} />
      <HorizontalStack>
        <Button
          secondary
          onClick={function () {
            props.onDone(undefined);
          }}
        >
          Cancel
        </Button>
        <Spacer width={16} />
        <Button onClick={onConfirm} loading={processing}>
          Confirm
        </Button>
      </HorizontalStack>
      <Spacer height={16} />
    </Stack>
  );
}
