import FullWidthLayout from "../../Cargo/Layout/FullWidthLayout";
export function APIAccessScreen() {
  return (
    <FullWidthLayout
      header="API Access"
      content={<div>API</div>}
      microcopy="Manage your API access and credentials"
    />
  );
}
