import Dropdown from "../../../../Cargo/Controls/Dropdown";
import { ErrorMessageType } from "../../../../Cargo/Validation";

interface TarpingRequiredDropdownProps {
  value: boolean | undefined;
  onChange: (newValue: boolean | undefined) => void;
  enabled: boolean;
  errorMessage: ErrorMessageType;
  forceValidation: boolean;
}

export function TarpingRequiredDropdown(props: TarpingRequiredDropdownProps) {
  const options = [
    {
      item: false,
      description: "Tarp Not Required",
    },
    {
      item: true,
      description: "Tarp Required",
    },
  ];

  return (
    <Dropdown
      selectedItem={props.value}
      onOptionSelected={function (newState: boolean) {
        props.onChange(newState);
      }}
      options={options}
      width={300}
      menuWidth={300}
      errorMessage={props.errorMessage}
      forceValidation={props.forceValidation}
      unselectedDescription="Select Tarp Preference"
      disabled={!props.enabled}
    />
  );
}
