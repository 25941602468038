import Colors from "../../Cargo/Colors";
import Button from "../../Cargo/Controls/Button";
import LinkButton from "../../Cargo/Controls/LinkButton";
import Icon from "../../Cargo/Icons/Icon";
import Box from "../../Cargo/Layout/Box";
import HorizontalStack from "../../Cargo/Layout/HorizontalStack";
import Spacer from "../../Cargo/Layout/Spacer";
import Stack from "../../Cargo/Layout/Stack";
import {
  ErrorMessage,
  Heading0,
  Legalese,
  Microcopy,
} from "../../Cargo/Text/Text";
import { UUID } from "../../Cargo/Types/types";
import { ErrorMessageType } from "../../Cargo/Validation";
import DeliveryColumn from "../Quotes/Components/QuoteRow/DeliveryColumn";
import PickupColumn from "../Quotes/Components/QuoteRow/PickupColumn";
import Progress from "../Quotes/Components/QuoteRow/Progress";
import TransitColumn from "../Quotes/Components/QuoteRow/TransitColumn";
import { describeHours } from "../../Helpers/describeHours";
import { isDomesticCanada } from "../../Helpers/isDomesticCanada";
import { useOnce } from "../../Hooks/useOnce";
import { useShipmentService } from "../../Services/ShipmentService";
import {
  useGetStartedApi,
  usePaymentMethodsApi,
  useSavedBrokersApi,
} from "../../apis";
import {
  BankAccount,
  BookingSuspendedReason,
  Broker,
  CreditCard,
  PreBookingShipment,
  SavedBroker,
} from "@freightsimple/generated-dashboard-openapi-client";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useChangePickupDatePreBooking } from "../ChangePickupDate/Hooks/useChangePickupDatePreBooking";
import { useCoupon } from "../Coupons/Hooks/useCoupon";
import LoadingShipment from "./Components/LoadingShipment";
import useChangeBrokerModal from "./Modals/useChangeBrokerModal";
import useChangeCreditCardModal from "./Modals/useChangeCreditCardModal";
import {
  setPickupDate,
  updateBroker,
  updateStripePaymentMethod,
} from "./Slices/bookShipmentSlice";
import {
  ColumnHeader,
  PrimaryText,
} from "../Quotes/Components/QuoteRow/QuoteRowSharedStyles";

interface ReviewProps {
  onBookShipment: () => void;
  shipment: PreBookingShipment;
  savedBrokers: Array<SavedBroker>;
  savedBrokerId: UUID | undefined;
  cards: Array<CreditCard>;
  bankAccounts: Array<BankAccount>;
  selectedStripePaymentMethodId: string | undefined;
  onCreditCardChanged: (_: string) => void;
  onBrokerChanged: (_: Broker) => void;
  onModifyDetails: () => void;
  onModifyPickupAddress: () => void;
  onModifyDeliveryAddress: () => void;
  onSelectAnotherQuote: () => void;
}

function Review(props: ReviewProps) {
  const { selectedQuote } = props.shipment;
  const { shipment } = props;
  const showChangeCreditCardModal = useChangeCreditCardModal();
  const showChangeBrokerModal = useChangeBrokerModal();
  const onChangePickupDate = useChangePickupDatePreBooking(shipment);
  const dispatch = useDispatch();
  const { discountQuote } = useCoupon();

  if (selectedQuote === undefined) {
    throw new Error("selectedQuote is undefined");
  }

  const discountedQuote = discountQuote(selectedQuote);

  const deliveryHours = describeHours(shipment.deliveryLocation.hours);

  const paymentTermsDays = shipment.paymentTermsDays;

  const selectedCard = shipment.stripePaymentMethodId
    ? props.cards.find(
        (card) => card.stripePaymentMethodId === shipment.stripePaymentMethodId,
      )
    : undefined;

  const selectedBankAccount = shipment.stripePaymentMethodId
    ? props.bankAccounts.find(
        (bankAccount) =>
          bankAccount.stripePaymentMethodId === shipment.stripePaymentMethodId,
      )
    : undefined;

  const noSelectedPaymentMethod =
    selectedCard === undefined &&
    selectedBankAccount === undefined &&
    paymentTermsDays === 0;

  const onChangeCreditCard =
    props.cards.length > 1 ||
    (selectedCard === undefined && props.cards.length > 0)
      ? async function () {
          const newStripePaymentMethodId = await showChangeCreditCardModal(
            selectedCard?.stripePaymentMethodId,
            props.cards,
          );

          if (newStripePaymentMethodId !== undefined) {
            props.onCreditCardChanged(newStripePaymentMethodId);
          }
        }
      : undefined;

  const onChangeBroker = async function () {
    const broker = props.shipment.broker;

    if (broker === undefined) {
      throw Error("broker is undefined - should not happen");
    }

    await showChangeBrokerModal(
      props.savedBrokerId,
      broker,
      props.savedBrokers,
      props.onBrokerChanged,
    );
  };

  async function changePickupDate() {
    const newDate = await onChangePickupDate();
    if (newDate !== undefined) {
      dispatch(setPickupDate(newDate));
    }
  }

  function isSameDayPickup() {
    const today = moment().startOf("day");
    const pickupDate = moment(props.shipment.pickupDate);
    return pickupDate.isSame(today, "day");
  }

  function isPickupInThePast() {
    const today = moment().startOf("day");
    const pickupDate = moment(props.shipment.pickupDate);
    return pickupDate.isBefore(today, "day");
  }

  function errorMessageForFinishBookingButton(): ErrorMessageType {
    if (isPickupInThePast()) {
      return "The selected pickup date is in the past. Please modify the pickup date";
    }

    const selectedQuote = props.shipment.selectedQuote;

    if (selectedQuote === undefined) {
      return "No quote is selected";
    }

    const selectedQuoteCreatedAt = selectedQuote.createdAt;

    if (selectedQuoteCreatedAt === undefined) {
      return "Not known when quote was created";
    }

    if (
      moment(selectedQuoteCreatedAt).isBefore(moment().subtract(30, "days"))
    ) {
      return "Quote expired (more than 30 days old)";
    }

    if (props.shipment.isBookingSuspended) {
      if (
        props.shipment.bookingSuspendedReason ===
        BookingSuspendedReason.OverdueInvoices
      ) {
        return "Booking is currently suspended. There are overdue invoices.";
      }

      if (
        props.shipment.bookingSuspendedReason ===
        BookingSuspendedReason.AlreadyOverCreditLimit
      ) {
        return "Booking is currently suspended. You are over the credit limit.";
      }

      if (
        props.shipment.bookingSuspendedReason ===
        BookingSuspendedReason.WillBeTooFarBeyondCreditLimit
      ) {
        return "Booking is currently suspended. This will go too far beyond your credit limit.";
      }

      if (
        props.shipment.bookingSuspendedReason ===
        BookingSuspendedReason.NeedsAccountVerification
      ) {
        return "Your account requires verification. Please contact us.";
      }
    }

    if (noSelectedPaymentMethod) {
      return "No payment method is selected for this shipment";
    }

    return undefined;
  }

  const errorMessage = errorMessageForFinishBookingButton();

  return (
    <Stack width="100%" align="left">
      <Heading0>Review and Book</Heading0>
      <Spacer height={8} />
      {isSameDayPickup() && (
        <>
          <Microcopy>
            <Icon
              name="exclamation-triangle"
              color={Colors.Gold}
              size={16}
              solid
              style={{ marginRight: "4px" }}
            />
            You are booking this for <strong>today</strong>. If the carrier is
            unable to pick it up today, it will be rescheduled for the next
            business day.
          </Microcopy>
          <Spacer height={16} />
        </>
      )}

      <Microcopy style={{ maxWidth: "800px" }}>
        Please double-check all the details are correct before booking the
        shipment. Incorrect information may lead to additional charges from the
        carrier later, which you will be responsible for. Delivery dates are
        subject to change due to unforeseen delays.
      </Microcopy>
      <Spacer height={16} />
      {paymentTermsDays !== 0 && (
        <>
          <Microcopy>
            An invoice for{" "}
            <strong style={{ color: Colors.NormalText }}>
              ${discountedQuote.price.toFixed(2)} {discountedQuote.currency}
            </strong>{" "}
            will be issued (Due in {paymentTermsDays} days)
          </Microcopy>
        </>
      )}
      {paymentTermsDays === 0 && selectedCard && (
        <>
          <Microcopy>
            A charge of{" "}
            <strong style={{ color: Colors.NormalText }}>
              ${discountedQuote.price} {discountedQuote.currency}
            </strong>{" "}
            will be made to your card
          </Microcopy>
        </>
      )}
      {paymentTermsDays === 0 && selectedBankAccount && (
        <>
          <Microcopy>
            A charge of{" "}
            <strong style={{ color: Colors.NormalText }}>
              ${discountedQuote.price} {discountedQuote.currency}
            </strong>{" "}
            will be made to your bank account
          </Microcopy>
        </>
      )}
      <Spacer height={32} />
      {errorMessage && (
        <>
          <ErrorMessage>{errorMessage}</ErrorMessage>
          <Spacer height={16} />
        </>
      )}
      <Button
        id="after_review_book_shipment"
        label={`Finish Booking!`}
        size="xl"
        onClick={props.onBookShipment}
        disabled={errorMessage !== undefined}
      />

      <Spacer height={16} />
      <Legalese>
        <div>
          By booking, you accept FreightSimple’s{" "}
          <LinkButton
            regularWeight={true}
            to="https://www.freightsimple.com/terms-of-service"
          >
            Terms and Conditions
          </LinkButton>
        </div>
      </Legalese>

      <Spacer height={64} />

      <Box style={{ width: "100%" }}>
        <Spacer height={48} />
        {shipment.selectedQuote?.notes && (
          <>
            <PrimaryText style={{ fontWeight: "bold" }}>Notes</PrimaryText>
            <ColumnHeader>
              {shipment.selectedQuote.notes}
            </ColumnHeader>
            <Spacer height={48} />
          </>
        )}
        <Progress shipmentState={shipment.shipmentState} />
        <Spacer height={16}></Spacer>
        <HorizontalStack width="100%" verticalAlign="top">
          <PickupColumn
            pickupDeadline={shipment.pickupDeadline}
            lineItems={shipment.lineItems}
            pickupDate={moment(shipment.pickupDate)}
            pickupLocation={shipment.pickupLocation}
            pickupContact={shipment.pickupContact}
            shipmentState={shipment.shipmentState}
            quote={selectedQuote}
            pickupReferenceNumber={shipment.pickupReferenceNumber}
            onModifyDetails={props.onModifyDetails}
            onModifyPickupAddress={props.onModifyPickupAddress}
            onModifyPickupContact={props.onModifyPickupAddress}
            onModifyPickupReferenceAndNotes={props.onModifyPickupAddress}
            onChangePickupDate={changePickupDate}
            isDomesticCanada={isDomesticCanada(
              shipment.pickupLocation,
              shipment.deliveryLocation,
            )}
            showNotesSection={true}
            pickupBoothNumber={shipment.pickupBoothNumber}
          />
          <TransitColumn
            quote={selectedQuote}
            shipmentState={shipment.shipmentState}
            proNumber={undefined}
            broker={shipment.broker}
            showCarrierLogo={true}
            showPriceSection={true}
            price={discountedQuote.price}
            currency={discountedQuote.currency}
            selectedCard={selectedCard}
            selectedBankAccount={selectedBankAccount}
            paymentTermsDays={paymentTermsDays}
            onChangePaymentMethod={onChangeCreditCard}
            onChangeBroker={onChangeBroker}
            onChangeQuote={props.onSelectAnotherQuote}
            addInsuranceToShipment={shipment.addInsuranceToShipment}
            insuranceAmount={shipment.insuranceAmount}
            insuranceCurrency={shipment.insuranceCurrency}
          />
          <DeliveryColumn
            quote={selectedQuote}
            deliveryHours={deliveryHours}
            deliveryLocation={shipment.deliveryLocation}
            deliveryContact={shipment.deliveryContact}
            shipmentState={shipment.shipmentState}
            actualDeliveryDate={undefined}
            actualDeliveryTime={undefined}
            expectedDeliveryDate={selectedQuote.expectedDeliveryDate}
            latestExpectedDeliveryDate={
              selectedQuote.latestEstimatedDeliveryDate
            }
            deliveryReferenceNumber={shipment.deliveryReferenceNumber}
            deliveryBoothNumber={shipment.deliveryBoothNumber}
            deliveryDeadline={shipment.deliveryDeadline}
            onModifyDetails={props.onModifyDetails}
            onModifyDeliveryAddress={props.onModifyDeliveryAddress}
            onModifyDeliveryContact={props.onModifyDeliveryAddress}
            onModifyDeliveryReferenceAndNotes={props.onModifyPickupAddress}
            showNotesSection={true}
          />
        </HorizontalStack>
        <Spacer height={48} />
      </Box>

      <Spacer height={24} />
    </Stack>
  );
}

interface BookShipmentReviewScreenProps {
  shipment: PreBookingShipment;
  onModifyDetails: () => void;
  onModifyPickupAddress: () => void;
  onModifyDeliveryAddress: () => void;
  onSelectAnotherQuote: () => void;
}

function BookShipmentReviewScreen(props: BookShipmentReviewScreenProps) {
  const { shipment } = props;
  const shipmentsService = useShipmentService();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { shipmentId } = shipment;

  const [savedBrokers, setSavedBrokers] = useState<Array<SavedBroker>>([]);
  const paymentMethodsApi = usePaymentMethodsApi();
  const [cards, setCards] = useState<Array<CreditCard>>([]);
  const [bankAccounts, setBankAccounts] = useState<Array<BankAccount>>([]);
  const [loadingCards, setLoadingCards] = useState(true);
  const [loadingBankAccounts, setLoadingBankAccounts] = useState(true);
  const getStartedApi = useGetStartedApi();
  const savedBrokersApi = useSavedBrokersApi();
  const [loadingSavedBrokers, setLoadingSavedBrokers] = useState(false);
  const [bookingInProgress, setBookingInProgress] = useState(false);

  async function loadSavedBrokers() {
    setLoadingSavedBrokers(true);
    const savedBrokersResponse = await savedBrokersApi.getAllSavedBrokers();
    setSavedBrokers(savedBrokersResponse.items);
    setLoadingSavedBrokers(false);
  }

  async function loadCreditCards() {
    setLoadingCards(true);
    const creditCardsResponse = await paymentMethodsApi.getAllPaymentMethods();

    if (creditCardsResponse.creditCards === undefined) {
      throw new Error("no credit cards");
    }

    setCards(creditCardsResponse.creditCards);
    setLoadingCards(false);
  }

  async function loadBankAccounts() {
    setLoadingBankAccounts(true);
    try {
      const response = await paymentMethodsApi.getAllPaymentMethods();

      setBankAccounts(response.bankAccounts);
    } catch (e) {
      console.error("Error with loadBankAccounts", { e });
      setBankAccounts([]);
    }

    setLoadingBankAccounts(false);
  }

  useOnce(async function () {
    shipmentsService.markReviewingPriorToBookingShipment(shipment.shipmentId);
  });

  useOnce(async function () {
    await loadSavedBrokers();
  });

  useOnce(async function () {
    await loadCreditCards();
  });

  useOnce(async function () {
    await loadBankAccounts();
  });

  async function onBookShipment() {
    setBookingInProgress(true);

    try {
      await shipmentsService.bookShipment(shipmentId);

      navigate(`/book/processing?shipmentId=${shipmentId}`);
    } catch (e) {
      console.error(e);
      navigate(`/book/nearlydone?shipmentId=${shipmentId}`);
    }
    setBookingInProgress(false);
  }

  if (loadingSavedBrokers) {
    return <LoadingShipment />;
  }

  if (bookingInProgress) {
    return <LoadingShipment />;
  }

  function findSavedBrokerId() {
    const broker = shipment.broker;

    const matchingSavedBroker = savedBrokers.find(function (b) {
      return (
        b.broker.businessName === broker?.businessName &&
        b.broker.address.postalCode === broker.address.postalCode
      );
    });

    return matchingSavedBroker?.savedBrokerId;
  }

  const savedBrokerId = findSavedBrokerId();

  const selectedStripePaymentMethodId = shipment.stripePaymentMethodId;

  async function onCreditCardChanged(newStripePaymentMethod: string) {
    dispatch(updateStripePaymentMethod(newStripePaymentMethod));
    await getStartedApi.setStripePaymentMethodId({
      getStartedSetStripePaymentMethodId: {
        stripePaymentMethodId: newStripePaymentMethod,
        shipmentId: shipmentId,
        newPaymentMethod: true,
      },
    });
  }

  async function onBrokerChanged(updatedBroker: Broker) {
    await getStartedApi.setBrokerDetails({
      setBrokerDetailsRequest: {
        shipmentId: props.shipment.shipmentId,
        broker: updatedBroker,
      },
    });
    await loadSavedBrokers();
    dispatch(updateBroker(updatedBroker));
  }

  if (
    shipment === undefined ||
    loadingCards === true ||
    loadingBankAccounts === true
  ) {
    return <LoadingShipment />;
  }

  return (
    <Review
      shipment={shipment}
      onBookShipment={onBookShipment}
      savedBrokerId={savedBrokerId}
      savedBrokers={savedBrokers}
      cards={cards}
      bankAccounts={bankAccounts}
      selectedStripePaymentMethodId={selectedStripePaymentMethodId}
      onCreditCardChanged={onCreditCardChanged}
      onModifyDetails={props.onModifyDetails}
      onModifyPickupAddress={props.onModifyPickupAddress}
      onModifyDeliveryAddress={props.onModifyDeliveryAddress}
      onSelectAnotherQuote={props.onSelectAnotherQuote}
      onBrokerChanged={onBrokerChanged}
    />
  );
}

export default BookShipmentReviewScreen;
