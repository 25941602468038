import Input from "../../../Cargo/Controls/Input";
import ProgressSpinner from "../../../Cargo/Icons/ProgressSpinner";
import { ProgressSpinnerSizes } from "../../../Cargo/Icons/ProgressSpinnerSizes";
import { ErrorMessageType } from "../../../Cargo/Validation";
import { formatPostalCode } from "../../../Helpers/formatPostalCode";
import { usePostalCodesApi } from "../../../apis";
import {
  Address,
  CountryCode,
  LatitudeLongitude,
} from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";
import styled from "styled-components";
import { isValidPostalCodeFormat } from "../Validators/errorMessageForPostalCode";

const CityPill = styled.div`
  height: 38px;
  border-radius: 10px;
  text-align: right;
  vertical-align: middle;
  padding: 6px 12px 6px 12px;
  color: var(--freightsimple-color-light-text);
`;

interface PostalCodePresentationalProps {
  postalCode: string;
  onUpdate: (postalCode: string) => void;
  city: string;
  stateOrProvinceCode: string;
  countryCode: CountryCode | undefined;
  loading: boolean;
  errorMessage: ErrorMessageType;
  warningMessage: ErrorMessageType;
  forceValidation: boolean;
  name: string;
  width?: number;
  autoFocus: boolean | undefined;
  displayName: string | undefined;
  enabled: boolean;
}

interface FlagProps {
  countryCode: CountryCode | undefined;
}

function Flag(props: FlagProps) {
  const { countryCode } = props;

  if (countryCode === undefined) {
    return <></>;
  }

  if (countryCode === CountryCode.Canada) {
    return (
      <img
        alt="Canada"
        style={{
          marginLeft: "5px",
          position: "relative",
          top: "-2px",
        }}
        width="24"
        height="24"
        src="/canadaFlag.png"
      />
    );
  }

  if (countryCode === CountryCode.UnitedStates) {
    return (
      <img
        alt="United States"
        style={{
          marginLeft: "5px",
          position: "relative",
          top: "-2px",
        }}
        width="24"
        height="24"
        src="/usFlag.png"
      />
    );
  }

  throw new Error("Not supported yet");
}

// Exported to allow for testing
export const PostalCodePresentational: React.FC<
  PostalCodePresentationalProps
> = (props: PostalCodePresentationalProps) => {
  function renderCityPill() {
    if (props.stateOrProvinceCode) {
      return (
        <CityPill>
          {props.city}, {props.stateOrProvinceCode}
          {"  "}
          <Flag countryCode={props.countryCode} />
        </CityPill>
      );
    }

    return undefined;
  }

  function spinner() {
    if (props.loading) {
      return <ProgressSpinner size={ProgressSpinnerSizes.Small} />;
    } else {
      return <></>;
    }
  }

  function inset() {
    return (
      <>
        {spinner()}
        {renderCityPill()}
      </>
    );
  }

  function onChange(value: string) {
    const postalCode = formatPostalCode(value);
    props.onUpdate(postalCode);
  }

  return (
    <>
      <Input
        id="postalCodeInput"
        name={props.name}
        type="text"
        value={props.postalCode}
        onChange={onChange}
        pattern="^(?:[A-Z]\d[A-Z] \d[A-Z]\d)$"
        inset={inset()}
        width={props.width}
        errorMessage={props.errorMessage}
        warningMessage={props.warningMessage}
        forceValidation={props.forceValidation}
        placeholder={
          props.displayName
            ? `Enter ${props.displayName} Postal Code`
            : `Enter Postal Code`
        }
        autoFocus={props.autoFocus}
        doNotDoTimeBasedValidation={true}
        enabled={props.enabled}
      ></Input>
    </>
  );
};

export interface PostalCodeChangeEvent {
  address: Partial<Address>;
  latitudeLongitude?: LatitudeLongitude;
}

type PostalCodeInputProps = {
  postalCode: string;
  city: string;
  stateOrProvinceCode: string;
  countryCode: CountryCode | undefined;
  onChange: (
    _: PostalCodeChangeEvent,
    distributionWarehouseName: string | undefined,
  ) => void;
  name: string;

  width?: number;

  errorMessage: ErrorMessageType;
  warningMessage: ErrorMessageType;
  forceValidation: boolean;

  autoFocus?: boolean;

  displayName: string | undefined;

  enabled?: boolean;
};

const PostalCodeInput: React.FC<PostalCodeInputProps> = (
  props: PostalCodeInputProps,
) => {
  const [loading, setLoading] = useState(false);

  const postalCodesApi = usePostalCodesApi();

  async function update(postalCode: string) {
    props.onChange(
      {
        address: {
          postalCode: postalCode,
          countryCode: undefined,
          stateOrProvinceCode: undefined,
          city: undefined,
        },
      },
      undefined,
    );

    if (isValidPostalCodeFormat(postalCode)) {
      setLoading(true);
      postalCodesApi
        .lookUpCity({
          postalCode: postalCode.replace(/ /g, ""),
        })
        .then((response) => {
          const coordinates =
            response.longitude !== undefined && response.latitude !== undefined
              ? {
                  longitude: response.longitude,
                  latitude: response.latitude,
                }
              : undefined;

          setLoading(false);
          props.onChange(
            {
              address: {
                postalCode: postalCode,
                countryCode: response.countryCode,
                stateOrProvinceCode: response.stateOrProvinceCode,
                city: response.city,
              },
              latitudeLongitude: coordinates,
            },
            response.distributionWarehouseName,
          );
        })
        .catch((e) => {
          console.error("Error looking up postal code", { e });
          alert("Error with postal code");
          setLoading(false);
        });
    } else {
      console.error("Not valid Postal Code");
    }
  }

  return (
    <PostalCodePresentational
      postalCode={props.postalCode}
      city={props.city}
      countryCode={props.countryCode}
      stateOrProvinceCode={props.stateOrProvinceCode}
      onUpdate={update}
      loading={loading}
      name={props.name}
      errorMessage={props.errorMessage}
      warningMessage={props.warningMessage}
      forceValidation={props.forceValidation}
      width={props.width}
      autoFocus={props.autoFocus}
      displayName={props.displayName}
      enabled={props.enabled === undefined ? true : props.enabled}
    ></PostalCodePresentational>
  );
};

export default PostalCodeInput;
