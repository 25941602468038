import { CSSProperties } from "styled-components";
import Animation from "../Animations/Animation";

interface YellowExclamationAnimatedIconProps {
  style?: CSSProperties;
}

const YellowExclamationAnimatedIcon: React.FC<
  YellowExclamationAnimatedIconProps
> = (props) => {
  const defaultStyle = {
    width: "25px",
    height: "25px",
    marginBottom: "8px",
    marginRight: "0",
  };
  return (
    <Animation
      style={props.style ? props.style : defaultStyle}
      lottieFilesUrl="https://edit.lottiefiles.com/?src=https%3A%2F%2Fassets4.lottiefiles.com%2Fpackages%2Flf20_HYlQBb.json"
      pathToAnimation="/animations/yellow-exclamation.json"
    />
  );
};
export default YellowExclamationAnimatedIcon;
