import Dropdown from "../../../Cargo/Controls/Dropdown";
import { ErrorMessageType } from "../../../Cargo/Validation";

interface ShipmentFrequencyDropdownProps {
  label?: string;
  shipmentFrequency: string | undefined;
  onShipmentFrequencySelected: (value: string) => void;
  errorMessage: ErrorMessageType;
  forceValidation: boolean;
  width?: number;
}

const shipmentFrequencyOptions = [
  { item: "Daily", description: "Most days" },
  { item: "Weekly", description: "Most weeks" },
  { item: "Occasional", description: "Occasionally" },
  { item: "SingleShipment", description: "Single Shipment" },
];

function ShipmentFrequencyDropdown(props: ShipmentFrequencyDropdownProps) {
  const {
    shipmentFrequency,
    onShipmentFrequencySelected,
    errorMessage,
    forceValidation,
  } = props;
  return (
    <Dropdown
      id="shipmentFrequencyDropdown"
      unselectedDescription="Select"
      selectedItem={shipmentFrequency}
      onOptionSelected={onShipmentFrequencySelected}
      options={shipmentFrequencyOptions}
      width={props.width ?? 270}
      menuWidth={props.width ?? 270}
      errorMessage={errorMessage}
      forceValidation={forceValidation}
      placeholder={props.label}
    />
  );
}
export default ShipmentFrequencyDropdown;
