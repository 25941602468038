import { useSavedCommoditiesApi } from "../../../apis";
import { UUID } from "../../../Cargo/Types/types";
import {
  AddSavedCommodityRequest,
  DeleteSavedCommodityRequest,
  ModifySavedCommodityRequest,
  SavedCommodity,
} from "@freightsimple/generated-dashboard-openapi-client";

export function useSavedCommoditiesService() {
  const savedCommoditiesApi = useSavedCommoditiesApi();

  return {
    addSavedCommodity: async function (savedCommodity: SavedCommodity) {
      const request: AddSavedCommodityRequest = {
        savedCommodity,
      };

      await savedCommoditiesApi.addSavedCommodity(request);
    },

    modifySavedCommodity: async function (savedCommodity: SavedCommodity) {
      const request: ModifySavedCommodityRequest = {
        savedCommodity,
      };

      await savedCommoditiesApi.modifySavedCommodity(request);
    },

    deleteSavedCommodity: async function (savedCommodityId: UUID) {
      const request: DeleteSavedCommodityRequest = {
        savedCommodityId,
      };
      await savedCommoditiesApi.deleteSavedCommodity(request);
    },

    getAllSavedCommodities: async function () {
      return savedCommoditiesApi.getAllSavedCommodities();
    },
  };
}
