import { useInvoicesApi } from "../../../apis";
import { InvoiceStatsResponse } from "@freightsimple/generated-dashboard-openapi-client";
import useInterval from "../../../Hooks/useInterval";
import { useOnce } from "../../../Hooks/useOnce";
import { useState } from "react";

export function useInvoicesStats() {
  const invoicesApi = useInvoicesApi();
  const [invoicesStats, setInvoicesStats] = useState<
    InvoiceStatsResponse | undefined
  >();

  async function refresh() {
    const response = await invoicesApi.getInvoiceStats();
    setInvoicesStats(response);
  }

  // Refresh every 3 minutes
  useInterval(
    function () {
      refresh();
    },
    3 * 60 * 1000,
  );

  useOnce(async () => {
    refresh();
  });

  return invoicesStats;
}
