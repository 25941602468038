import { ErrorMessageType } from "../Validation";
import { isEmptyOrUndefined } from "../../Helpers/isNotEmptyOrUndefined";
import {
  convertFromUnit,
  convertToUnit,
  Dimension,
  Units,
} from "../../Helpers/units";

import Input from "./Input";
import { MicroWarningQuestionBubble } from "./QuestionBubble";
import HorizontalStack from "../Layout/HorizontalStack";
import YellowExclamationAnimatedIcon from "../Icons/YellowExclamationAnimatedIcon";
import Spacer from "../Layout/Spacer";
import { ReactNode } from "react";

interface NumberInputProps {
  id?: string;
  label?: string;
  autoComplete?: string;
  value?: number;
  onChange?: (e: number | undefined) => void;
  name?: string;
  enabled?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  pattern?: string;
  readOnly?: boolean;
  hidden?: boolean;
  width?: number;
  errorMessage: ErrorMessageType;
  forceValidation: boolean;
  hideErrorIcon?: boolean;
  labelPadding?: number;
  validateOnTimeoutMs?: number;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  units: Units;
  dimension: Dimension;
  onlyDigits?: boolean;
  maxSizeWarning?: number;
  minSizeWarning?: number;
}

const NumberInput: React.FC<NumberInputProps> = (props: NumberInputProps) => {
  const value =
    convertToUnit(props.value, props.units, props.dimension)?.toString() ?? "";

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (!props.onlyDigits) return;

    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
    }
  }

  function handleChange(input: string) {
    if (isEmptyOrUndefined(input)) {
      props.onChange?.(undefined);
      return;
    }

    const number = Number(input);

    if (Number.isNaN(number)) {
      return;
    }

    props.onChange?.(convertFromUnit(number, props.units, props.dimension));
  }

  const minSizeWarning = handleMinSizeWarning();
  const maxSizeWarning = handleMaxSizeWarning();

  const showSizeWarning = maxSizeWarning || minSizeWarning ? true : false;

  function handleMaxSizeWarning() {
    if (props.maxSizeWarning && props.value) {
      if (props.value > props.maxSizeWarning) {
        return true;
      }
    }
    return false;
  }

  function handleMinSizeWarning() {
    if (props.minSizeWarning && props.value) {
      if (props.value <= props.minSizeWarning) {
        return true;
      }
    }
    return false;
  }

  function maxSizeWarningDescription() {
    return (
      <>
        Too long?
        <MicroWarningQuestionBubble
          description={
            <div style={{ width: "300px" }}>
              <HorizontalStack>
                <YellowExclamationAnimatedIcon
                  style={{ height: 20, width: 20, marginBottom: 5 }}
                />{" "}
                <Spacer width={5} /> <strong>Too long?</strong>{" "}
              </HorizontalStack>
              <Spacer height={8} />
              <p>
                Seems like a high value. Make sure it’s correct before
                proceeding.
              </p>
            </div>
          }
        />
      </>
    );
  }

  function minSizeWarningDescription() {
    return (
      <div style={{ whiteSpace: "nowrap" }}>
        Too small?
        <MicroWarningQuestionBubble
          description={
            <div style={{ width: "300px" }}>
              <HorizontalStack>
                <YellowExclamationAnimatedIcon
                  style={{ height: 20, width: 20, marginBottom: 5 }}
                />{" "}
                <Spacer width={5} /> <strong>Too small?</strong>{" "}
              </HorizontalStack>
              <Spacer height={8} />
              <p style={{ whiteSpace: "wrap" }}>
                Seems like a small value. Make sure it’s correct before
                proceeding.
              </p>
            </div>
          }
        />
      </div>
    );
  }

  function handleSizeWarningMessage(): ReactNode | undefined {
    return minSizeWarning
      ? minSizeWarningDescription()
      : maxSizeWarning
        ? maxSizeWarningDescription()
        : undefined;
  }

  return (
    <Input
      id={props.id}
      label={props.label}
      autoComplete={props.autoComplete}
      value={value}
      onChange={handleChange}
      name={props.name}
      enabled={props.enabled}
      autoFocus={props.autoFocus}
      required={props.required}
      readOnly={props.readOnly}
      hidden={props.hidden}
      width={props.width}
      onKeyDown={handleKeyDown}
      type="number"
      errorMessage={props.errorMessage}
      forceValidation={props.forceValidation}
      hideErrorIcon={props.hideErrorIcon}
      labelPadding={props.labelPadding}
      validateOnTimeoutMs={props.validateOnTimeoutMs}
      onBlur={props.onBlur}
      showMaxSizeWarning={showSizeWarning}
      warningMessage={showSizeWarning && handleSizeWarningMessage()}
    />
  );
};
export default NumberInput;
