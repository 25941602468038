import Animation from "../Animations/Animation";

const ErrorAnimation: React.FC = () => {
  return (
    <Animation
      speed={0.4}
      loop={false}
      pathToAnimation="https://assets4.lottiefiles.com/temp/lf20_yYJhpG.json"
      lottieFilesUrl="https://edit.lottiefiles.com/?src=https%3A%2F%2Fassets4.lottiefiles.com%2Ftemp%2Flf20_yYJhpG.json"
    />
  );
};
export default ErrorAnimation;
