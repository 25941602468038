import { RedocStandalone } from "redoc";
import { redocOptions } from "./Styles/RedocTheme";
import "./Styles/Redoc.css";

export function APIDocumentation() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        scrollPadding: "0 !important",
        scrollBehavior: "smooth",
        overflowY: "scroll",
      }}
    >
      <RedocStandalone
        specUrl="/api-spec/freightsimple-openapi-spec-v1.yaml"
        options={redocOptions}
      />
    </div>
  );
}
