import Dropdown from "../../../Cargo/Controls/Dropdown";
import { dropdownDivider } from "../../../Cargo/Controls/dropdownDivider";
import { UUID } from "../../../Cargo/Types/types";
import { ErrorMessageType } from "../../../Cargo/Validation";
import { SavedLocation } from "@freightsimple/generated-dashboard-openapi-client";

interface BranchesDropdownProps {
  branchId: UUID | undefined;
  setBranchId: (_: UUID | undefined) => void;
  branches: SavedLocation[];
  forceValidation?: boolean;
  errorMessage?: ErrorMessageType;
}

function BranchesDropdown(props: BranchesDropdownProps) {
  const options = [
    // Magic string
    { item: "no_branch_assigned", description: "No branch assigned" },
    dropdownDivider(),
    ...props.branches.map((t) => {
      return {
        item: t.savedLocationId,
        description: t.location.businessName,
      };
    }),
  ];

  return (
    <Dropdown
      id="branchesDropdown"
      selectedItem={props.branchId}
      onOptionSelected={props.setBranchId}
      options={options}
      width={349}
      menuWidth={349}
      errorMessage={props.errorMessage}
      warningMessage={undefined}
      forceValidation={props.forceValidation}
      unselectedDescription="No branch assigned"
    ></Dropdown>
  );
}

export default BranchesDropdown;
