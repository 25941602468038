import Dropdown from "../../../Cargo/Controls/Dropdown";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { ErrorMessageType } from "../../../Cargo/Validation";
import BillingAddressInput from "./BillingAddressInput";
import { Address } from "@freightsimple/generated-dashboard-openapi-client";
import { useOnce } from "../../../Hooks/useOnce";
import { useState } from "react";

interface CardAddressProps {
  addresses: Array<Address>;

  address: Partial<Address>;
  onAddressChanged: (_: Partial<Address>) => void;
  onResetAddress: () => void;

  validated: boolean;

  forceValidation: boolean;
  errorMessageForAddress: ErrorMessageType;
  errorMessageForCity: ErrorMessageType;
  errorMessageForStateOrProvinceCode: ErrorMessageType;
  errorMessageForPostalCode: ErrorMessageType;
  errorMessageForCountry: ErrorMessageType;
}

const CardAddress: React.FC<CardAddressProps> = (props: CardAddressProps) => {
  const [showCustomAddressForm, setShowCustomAddressForm] = useState(
    props.addresses.length === 0,
  );
  const [selectedIndex, setSelectedIndex] = useState(0);

  const options = [
    ...props.addresses.map((a) => {
      return {
        item: a,
        description: [a.addressLine, a.city, a.postalCode]
          .filter((i) => i !== "")
          .join(", "),
      };
    }),

    {
      item: null,
      description: `Enter a different address...`,
    },
  ];

  function onOptionSelected(item: Address | null, index: number) {
    setSelectedIndex(index);
    if (index === props.addresses.length) {
      setShowCustomAddressForm(true);
      props.onResetAddress();
    } else {
      if (!item) {
        throw new Error("Missing item");
      }

      setShowCustomAddressForm(false);
      props.onAddressChanged(item);
    }
  }

  useOnce(function () {
    if (props.addresses.length > 0) {
      onOptionSelected(props.addresses[selectedIndex], selectedIndex);
    }
  });

  return (
    <Stack align="left">
      {props.addresses.length > 0 && (
        <Dropdown
          selectedItem={options[selectedIndex].item}
          options={options}
          onOptionSelected={onOptionSelected}
          width={520}
          menuWidth={520}
        />
      )}
      <Spacer height={11} />
      {showCustomAddressForm && (
        <BillingAddressInput
          enabled={true}
          address={props.address}
          addressChanged={props.onAddressChanged}
          forceValidation={props.forceValidation}
          errorMessageForAddress={props.errorMessageForAddress}
          errorMessageForCity={props.errorMessageForCity}
          errorMessageForStateOrProvinceCode={
            props.errorMessageForStateOrProvinceCode
          }
          errorMessageForPostalCode={props.errorMessageForPostalCode}
          errorMessageForCountry={props.errorMessageForCountry}
        />
      )}
    </Stack>
  );
};
export default CardAddress;
