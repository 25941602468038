import Colors from "../Colors";
import RedExclamationAnimatedIcon from "../Icons/RedExclamationAnimatedIcon";
import YellowExclamationAnimatedIcon from "../Icons/YellowExclamationAnimatedIcon";
import HorizontalStack from "../Layout/HorizontalStack";
import { ErrorMessageType } from "../Validation";
import React, { ReactNode, useState } from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import Spacer from "../Layout/Spacer";

interface PlaceholderLabelProps {
  disabled: boolean;
  $labelPadding: number;
}

const PlaceholderLabel = styled(({ ...rest }) => (
  <Form.Label {...rest} />
))<PlaceholderLabelProps>`
  height: 12px;
  padding: 0;
  position: absolute;
  top: ${(props) => -(props.$labelPadding - 4)}px;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default <label> margin */
  line-height: 1.5;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  font-size: 12px;
  color: ${(props) =>
    props.disabled ? Colors.VeryLightGray : Colors.LightText};
`;

function width(width: number | undefined) {
  if (typeof width === "number") {
    return `${width}px`;
  } else {
    return "100%";
  }
}

interface InputFormGroupProps {
  width: number | undefined;
}

const InputFormGroup = styled(Form.Group)<InputFormGroupProps>`
  position: relative;
  width: ${(props) => width(props.width)};
  margin-top: 2px;
  margin-bottom: 2px;
`;

const Inset = styled.div`
  position: absolute; /* to align it to right and positon it over the input */
  bottom: 0;
  right: 0;
`;

const LeftOutset = styled.div`
  position: relative;
  margin-right: 4px;
  top: 8px;
`;

interface StyledControlProps {
  width?: number;
  $hasPlaceholder: boolean;
  isInvalid: boolean;
  isWarning: boolean;
  disabled: boolean;
  readOnly: boolean;
}

function borderColor(
  prominent: boolean,
  isInvalid: boolean,
  disabled: boolean,
  readOnly: boolean,
  isWarning: boolean,
) {
  if (readOnly) {
    return Colors.LightGray;
  }

  if (disabled) {
    return Colors.VeryLightGray;
  }

  if (isInvalid) {
    return Colors.Red;
  }

  if (isWarning) {
    return Colors.Gold;
  }

  if (prominent === true) {
    return Colors.Blue;
  } else {
    return Colors.LightText;
  }
}

function padding(isInvalid: boolean) {
  if (isInvalid) {
    return "0";
  } else {
    return "1px";
  }
}

function borderThickness(isInvalid: boolean) {
  if (isInvalid) {
    return "2px";
  } else {
    return "1px";
  }
}

function color(readOnly: boolean) {
  if (readOnly) {
    return "#bbb";
  }

  return "#495057";
}

const StyledControl = styled(({ ...rest }) => (
  <Form.Control {...rest} />
))<StyledControlProps>`
  border-radius: 0 !important;
  border: none !important;
  width: ${(props) => width(props.width)} !important;
  height: ${(props) => (props.$hasPlaceholder ? "55px" : "48px")} !important;

  font-weight: var(--nhu-font-weight-bold) !important;
  font-size: 20px !important;
  color: ${(props) => color(props.readOnly)} !important;

  border-bottom: ${(props) => borderThickness(props.isInvalid)} solid
    ${(props) =>
      borderColor(
        false,
        props.isInvalid,
        props.disabled,
        props.readOnly,
        props.isWarning,
      )} !important;
  padding-bottom: ${(props) => padding(props.isInvalid)} !important;

  padding-left: 0 !important;
  padding-top: ${(props) =>
    props.$hasPlaceholder ? "20px" : "8px"} !important;
  padding-right: 0 !important;

  &:-webkit-autofill {
    font-weight: var(--nhu-font-weight-bold) !important;
    font-size: 20px !important;
    color: #495057 !important;
  }

  &:focus {
    border-bottom: 2px solid
      ${(props) =>
        borderColor(
          true,
          props.isInvalid,
          props.disabled,
          props.readOnly,
          props.isWarning,
        )} !important;
    padding-bottom: 0 !important;
    box-shadow: none !important;
  }

  &:hover {
    border-bottom: ${(props) => (props.readOnly ? "1px" : "2px")} solid
      ${(props) =>
        borderColor(
          true,
          props.isInvalid,
          props.disabled,
          props.readOnly,
          props.isWarning,
        )} !important;
    padding-bottom: ${(props) => (props.readOnly ? "1px" : "0")} !important;
    box-shadow: none !important;
  }

  &:hover:disabled {
    border-bottom: ${(props) => borderThickness(props.isInvalid)} solid
      ${(props) =>
        borderColor(
          false,
          props.isInvalid,
          props.disabled,
          props.readOnly,
          props.isWarning,
        )} !important;
    padding-bottom: ${(props) => padding(props.isInvalid)} !important;
  }

  &:valid {
    background-image: none !important;
  }

  &:invalid {
    background-image: none !important;
  }

  &::placeholder {
    font-weight: var(--nhu-font-weight-regular) !important;
    color: #bbb !important;
  }
`;

const StyledControlContainer = styled.div`
  position: relative; /* for absolute child element */
  display: inline-block; /* to take the width of the input */
  width: 100%;
`;

export const InvalidLabel = styled.div`
  color: #ea554c;
  font-size: 12px;
  margin-top: 4px;
`;

export const WarnLabel = styled.div`
  color: ${Colors.NormalText};
  font-size: 12px;
  margin-top: 4px;
`;

const SubtextLabel = styled.div`
  height: 18px;
  font-size: 12px;
  margin-top: 4px;
`;

export interface InputProps {
  id?: string;
  label?: ReactNode;
  autoComplete?: string;
  type: string;
  value?: string | number;
  onChange?: (
    newValue: string,
    e: React.FormEvent<HTMLInputElement> | undefined,
  ) => void;
  name?: string;
  enabled?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  pattern?: string;
  readOnly?: boolean;
  hidden?: boolean;
  width?: number;
  inset?: ReactNode;
  leftOutset?: ReactNode;

  maxLength?: number;

  warningMessage?: ErrorMessageType;
  errorMessage?: ErrorMessageType;
  showMaxSizeWarning?: boolean;
  forceValidation?: boolean;

  hideErrorIcon?: boolean;

  placeholder?: string;
  labelPadding?: number;

  onFocus?: (e: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;

  doNotDoTimeBasedValidation?: boolean;
  doNotValidateOnBlur?: boolean;
  validateOnTimeoutMs?: number;

  subtext?: ReactNode;

  warn?: boolean;
}

const Input: React.FC<InputProps> = (props: InputProps) => {
  const [validate, setValidate] = useState(false);

  function showError(): boolean {
    return (
      (validate || Boolean(props.forceValidation)) &&
      props.errorMessage !== undefined
    );
  }

  function showWarning() {
    return (
      (validate || props.forceValidation) && props.warningMessage !== undefined
    );
  }

  const disabled = props.enabled === false;

  return (
    <InputFormGroup
      style={{ visibility: props.hidden ? "hidden" : "visible" }}
      width={props.width}
    >
      <StyledControlContainer>
        <HorizontalStack>
          {props.leftOutset && (
            <LeftOutset>
              <HorizontalStack>{props.leftOutset}</HorizontalStack>
            </LeftOutset>
          )}
          <StyledControl
            isWarning={props.showMaxSizeWarning!}
            id={props.id}
            type={props.type}
            required={props.required}
            autoFocus={props.autoFocus}
            autoComplete={props.autoComplete}
            value={props.value}
            onChange={(e: React.FormEvent<HTMLInputElement>): void => {
              // Strip non-ASCII characters from the input value
              const sanitizedValue = e.currentTarget.value.replace(
                /[^\x20-\x7F]/g,
                "",
              );
              props.onChange?.(sanitizedValue, e);

              if (
                props.doNotDoTimeBasedValidation === undefined ||
                props.doNotDoTimeBasedValidation === false
              ) {
                setTimeout(() => {
                  setValidate(true);
                }, props.validateOnTimeoutMs || 6000);
              }
            }}
            name={props.name}
            disabled={Boolean(disabled)}
            pattern={props.pattern || ".*"}
            readOnly={Boolean(props.readOnly)}
            width={props.width}
            $hasPlaceholder={props.label !== undefined}
            isInvalid={showError() && !props.warn}
            onFocus={props.onFocus}
            onBlur={(e: React.FormEvent<HTMLInputElement>) => {
              if (!props.doNotValidateOnBlur) {
                setValidate(true);
              }
              props.onBlur?.(e);
            }}
            onKeyDown={props.onKeyDown}
            placeholder={props.placeholder}
            maxLength={props.maxLength}
          />
        </HorizontalStack>
        <Inset>
          <HorizontalStack>
            {props.inset}
            {!props.warn && showError() && !props.hideErrorIcon && (
              <RedExclamationAnimatedIcon />
            )}
            {props.warn && <YellowExclamationAnimatedIcon />}
          </HorizontalStack>
        </Inset>
      </StyledControlContainer>
      {props.label && (
        <PlaceholderLabel
          disabled={Boolean(disabled)}
          $labelPadding={props.labelPadding || 0}
        >
          {props.label}
        </PlaceholderLabel>
      )}

      {showError() && !props.warn && (
        <InvalidLabel>{props.errorMessage}</InvalidLabel>
      )}

      {showError() && props.warn && <WarnLabel>{props.errorMessage}</WarnLabel>}

      {showWarning() && !showError() && (
        <WarnLabel>{props.warningMessage}</WarnLabel>
      )}

      {!showError() && !props.subtext && <Spacer height={18} />}
      {!showError() && !showWarning() && props.subtext && (
        <SubtextLabel>{props.subtext}</SubtextLabel>
      )}
    </InputFormGroup>
  );
};
export default Input;
