import Dropdown from "../../../../Cargo/Controls/Dropdown";
import { getHandlingUnitTypesForDropdown } from "../../../../Data/HandlingUnitTypes";
import { HandlingUnitType } from "@freightsimple/generated-dashboard-openapi-client";

interface HandlingUnityTypeControlProps {
  usePluralForm: boolean;
  onChange: (value: HandlingUnitType) => void;
  value: HandlingUnitType | undefined;
  placeholder?: string;
}

const HandlingUnitTypeControl: React.FC<HandlingUnityTypeControlProps> = (
  props: HandlingUnityTypeControlProps,
) => {
  const options = getHandlingUnitTypesForDropdown(props.usePluralForm);

  function onOptionSelected(item: HandlingUnitType) {
    props.onChange(item);
  }

  const unselectedDescription = props.usePluralForm
    ? "Select Handling Units"
    : "Select Handling Unit";

  return (
    <>
      <Dropdown
        placeholder={props.placeholder}
        id="handlingUnitTypeControl"
        unselectedDescription={unselectedDescription}
        onOptionSelected={onOptionSelected}
        options={options}
        selectedItem={props.value}
        width={250}
        menuWidth={250}
      />
    </>
  );
};
export default HandlingUnitTypeControl;
