import { useUsersApi } from "../../../apis";
import Colors from "../../../Cargo/Colors";
import Button from "../../../Cargo/Controls/Button";
import LinkButton from "../../../Cargo/Controls/LinkButton";
import Icon from "../../../Cargo/Icons/Icon";
import ProgressSpinner from "../../../Cargo/Icons/ProgressSpinner";
import { ProgressSpinnerSizes } from "../../../Cargo/Icons/ProgressSpinnerSizes";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { Heading3, Microcopy } from "../../../Cargo/Text/Text";
import { rootUrl } from "../../../environment";
import { useOnce } from "../../../Hooks/useOnce";
import { useState } from "react";
import useClipboard from "react-use-clipboard";
import styled from "styled-components";

interface ShowReferralCodeModalProps {
  onFinished: () => void;
}

const LinkText = styled.pre`
  background-color: ${Colors.VeryLightGray};
  border: 1px solid ${Colors.LightGray};
  color: ${Colors.NormalText};
  padding: 10px;
  margin: 0;
`;

export function ShowReferralCodeModal(props: ShowReferralCodeModalProps) {
  const usersApi = useUsersApi();
  const [referralCode, setReferralCode] = useState<string | undefined>();

  async function refreshReferralCode() {
    const response = await usersApi.getReferralCode();
    setReferralCode(response.referralCode);
  }

  const url = `${rootUrl()}referral?code=${referralCode}`;

  useOnce(refreshReferralCode);

  const [isCopied, setCopied] = useClipboard(url);

  return (
    <Stack align="left" style={{ padding: "16px", width: "1024px" }}>
      <Spacer height={16} />

      {!referralCode && (
        <>
          <ProgressSpinner size={ProgressSpinnerSizes.Small} />
        </>
      )}
      {referralCode && (
        <>
          <Heading3>Referral Code</Heading3>
          <Microcopy>
            Give $100 and get $100 giftcard for yourself. If you love the
            FreightSimple experience as much as we do, perhaps you would like to
            share it with your contacts. We'll give them $100 off their first
            shipment, and when they book you'll get a $100 gift card yourself.
          </Microcopy>
          <Spacer height={8} />
          <Microcopy>You can copy this link and send it to them.</Microcopy>
          <Spacer height={16} />
          <HorizontalStack verticalAlign="middle">
            <LinkText>{url}</LinkText>
            <Spacer width={8} />
            <LinkButton onClick={setCopied}>
              Click here to copy to clipboard
            </LinkButton>
            {isCopied && (
              <Icon
                name="check"
                color={Colors.Green}
                size={16}
                solid
                style={{ marginLeft: "8px" }}
              />
            )}
          </HorizontalStack>
        </>
      )}
      <Spacer height={32} />
      <Button onClick={props.onFinished}>Done</Button>
      <Spacer height={16} />
    </Stack>
  );
}
