import Colors from "../../../Cargo/Colors";
import Button from "../../../Cargo/Controls/Button";
import LinkButton from "../../../Cargo/Controls/LinkButton";
import Icon from "../../../Cargo/Icons/Icon";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { Heading1, Legalese, Microcopy } from "../../../Cargo/Text/Text";
import { UUID } from "../../../Cargo/Types/types";
import { rootUrl } from "../../../environment";

import useClipboard from "react-use-clipboard";
import styled from "styled-components";

interface ShareTrackingModalProps {
  trackingId: UUID;
  onDone: () => void;
}

const LinkText = styled.pre`
  background-color: ${Colors.VeryLightGray};
  border: 1px solid ${Colors.LightGray};
  color: ${Colors.NormalText};
  padding: 10px;
  margin: 0;
`;

export const ShareTrackingModal: React.FC<ShareTrackingModalProps> = (
  props: ShareTrackingModalProps,
) => {
  const trackingUrl = `${rootUrl()}t?tId=${props.trackingId}`;
  const [isTrackingCopied, setTrackingCopied] = useClipboard(trackingUrl);

  return (
    <Stack align="left" style={{ padding: "16px", width: "850px" }}>
      <Spacer height={16} />
      <Heading1>Share Shipment Information</Heading1>
      <Spacer height={24} />
      <Microcopy style={{ maxWidth: "800px" }}>
        You can send this link to someone who doesn&apos;t have an account with
        FreightSimple. This will allow them to get all the information they need
        for pickup or delivery
      </Microcopy>
      <Spacer height={32} />
      <HorizontalStack verticalAlign="middle">
        <LinkText>{trackingUrl}</LinkText>
        <Spacer width={8} />
        <LinkButton onClick={setTrackingCopied}>
          Click here to copy to clipboard
        </LinkButton>
        {isTrackingCopied && (
          <Icon
            name="check"
            color={Colors.Green}
            size={16}
            solid
            style={{ marginLeft: "8px" }}
          />
        )}
      </HorizontalStack>
      <Spacer height={8} />
      <Legalese>
        Please note, this does not share billing information with them
      </Legalese>

      <Spacer height={64} />
      <Button onClick={props.onDone}>Done</Button>
      <Spacer height={16} />
    </Stack>
  );
};
