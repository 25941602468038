import Dropdown from "../../../../../Cargo/Controls/Dropdown";
import Input from "../../../../../Cargo/Controls/Input";
import LinkButton from "../../../../../Cargo/Controls/LinkButton";
import Box from "../../../../../Cargo/Layout/Box";
import HorizontalStack from "../../../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../../../Cargo/Layout/Spacer";
import { describeHazardClass } from "../../../Helpers/describeHazardClass";
import { NumberPackagesQuestionBubble } from "../../../../LineItems/Components/LineItem/QuestionBubbles/NumberPackagesQuestionBubble";
import { PackagingTypeQuestionBubble } from "../../../../LineItems/Components/LineItem/QuestionBubbles/PackagingTypeQuestionBubble";
import { TechnicalNameQuestionBubble } from "../../../../LineItems/Components/LineItem/QuestionBubbles/TechnicalNameQuestionBubble";
import { LineItem } from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";

interface EnterDetailsTabProps {
  lineItem: Partial<LineItem>;

  forceValidation: boolean;
  onChange: (updates: Partial<LineItem>) => void;

  onChangeUnNumber: () => void;
}

export function EnterDetailsTab(props: EnterDetailsTabProps) {
  const {
    dangerousUnNumber,
    dangerousClassification,
    dangerousPackingGroup,
    dangerousProperShippingName,
    dangerousTechnicalName,
    dangerousNumberPackages,
    dangerousPackagingType,
  } = props.lineItem;

  const [wasPackingGroupSelected] = useState(
    props.lineItem.dangerousPackingGroup !== undefined,
  );

  function errorMessageForNumberPackages() {
    if (dangerousNumberPackages === undefined) {
      return "Required";
    }
    return undefined;
  }

  function errorMessageForPackagingType() {
    if (dangerousPackagingType === undefined) {
      return "Required";
    }
    return undefined;
  }

  function errorMessageForPackingGroup() {
    if (dangerousPackingGroup === undefined || dangerousPackingGroup === "") {
      return "Required";
    }
    return undefined;
  }

  return (
    <Box width={800} style={{ height: "512px" }}>
      <HorizontalStack verticalAlign="middle">
        <Input
          type="text"
          label="UN Number"
          value={dangerousUnNumber}
          readOnly
          inset={
            <div style={{ position: "relative", top: "-6px" }}>
              <LinkButton onClick={props.onChangeUnNumber}>
                Change UN Number
              </LinkButton>
            </div>
          }
        />
      </HorizontalStack>
      <Input
        type="text"
        label="Proper Shipping Name"
        value={dangerousProperShippingName}
        readOnly
      />
      <Input
        type="text"
        label="Hazard Classification"
        value={describeHazardClass(dangerousClassification || "")}
        readOnly
      />
      {!wasPackingGroupSelected && (
        <>
          <Dropdown
            width={734}
            menuWidth={734}
            placeholder="Packing Group"
            unselectedDescription="Select a packing group"
            selectedItem={dangerousPackingGroup}
            options={[
              {
                item: "I",
                description: "Packing Group I - High Danger",
              },
              {
                item: "II",
                description: "Packing Group II - Medium Danger",
              },
              {
                item: "III",
                description: "Packing Group III - Low Danger",
              },
            ]}
            onOptionSelected={function (value) {
              props.onChange({ dangerousPackingGroup: value });
            }}
            errorMessage={errorMessageForPackingGroup()}
            forceValidation={props.forceValidation}
          />
          <Spacer height={16} />
        </>
      )}
      {wasPackingGroupSelected && (
        <Input
          type="text"
          label="Packing Group"
          value={`Packing Group ${dangerousPackingGroup}`}
          readOnly
        />
      )}
      <HorizontalStack>
        <Input
          name="package_count"
          type="text"
          label={
            <>
              Package Count
              <NumberPackagesQuestionBubble />
            </>
          }
          value={dangerousNumberPackages}
          onChange={function (value) {
            const packageRegex = new RegExp("^[1-9]\\d*$");
            if (value === "") {
              props.onChange({
                dangerousNumberPackages: undefined,
              });
            }
            if (value.match(packageRegex)) {
              props.onChange({
                dangerousNumberPackages: parseInt(value),
              });
            }
          }}
          pattern="\d+"
          errorMessage={errorMessageForNumberPackages()}
          forceValidation={props.forceValidation}
        />
        <Spacer width={32} />
        <Input
          name="packaging_type"
          type="text"
          label={
            <>
              Packaging Type
              <PackagingTypeQuestionBubble />
            </>
          }
          value={dangerousPackagingType}
          onChange={function (value) {
            props.onChange({ dangerousPackagingType: value });
          }}
          errorMessage={errorMessageForPackagingType()}
          forceValidation={props.forceValidation}
        />
      </HorizontalStack>
      <Input
        type="text"
        label={
          <>
            Technical Name (Optional)
            <TechnicalNameQuestionBubble />
          </>
        }
        value={dangerousTechnicalName}
        onChange={function (value) {
          props.onChange({ dangerousTechnicalName: value });
        }}
      />
    </Box>
  );
}
