import { RedocRawOptions, SideNavStyleEnum } from "redoc";

export const redocTheme = {
  logo: {
    gutter: "20px",
  },
  colors: {
    primary: {
      main: "#4A69BD",
    },
    success: {
      main: "#00875A",
      light: "#57D9A3",
    },
    error: {
      main: "#DE350B",
      light: "#FF5630",
    },
    warning: {
      main: "#FF8B00",
      light: "#FFB84D",
    },
    text: {
      primary: "#172B4D",
      secondary: "#6B778C",
    },
  },
  typography: {
    fontFamily: "Inter, system-ui, -apple-system, sans-serif",
    // headings: {
    //   fontFamily: "Inter, system-ui, -apple-system, sans-serif",
    //   fontWeight: "700",
    // },
    // heading1: {
    //   fontSize: "32px",
    //   fontWeight: "700",
    //   lineHeight: "1.2",
    //   color: "#172B4D",
    //   marginBottom: "24px",
    // }
  },
  sidebar: {
    backgroundColor: "#F4F5F7",
    textColor: "#172B4D",
  },
  rightPanel: {
    backgroundColor: "#0E1624",
    textColor: "#F4F5F7",
  },
};

export const redocOptions: RedocRawOptions = {
  theme: redocTheme,
  nativeScrollbars: false,
  hideDownloadButton: false,
  expandResponses: "200,201",
  pathInMiddlePanel: true,
  disableSearch: false,
  requiredPropsFirst: true,
  sortPropsAlphabetically: false,
  sideNavStyle: SideNavStyleEnum.IdOnly,
  menuToggle: false
  
};
