import Dropdown from "../../../../Cargo/Controls/Dropdown";
import { dropdownDivider } from "../../../../Cargo/Controls/dropdownDivider";
import { DropdownElement } from "../../../../Cargo/Controls/DropdownElement";
import { ErrorMessageType } from "../../../../Cargo/Validation";
import { LocationTypeDescription } from "../../../../Data/LocationTypes";
import locationTypes from "../../../../GeneratedFixtures/LocationTypes.json";
import { assertNever } from "../../../../Helpers/assertNever";
import { LocationType } from "@freightsimple/generated-dashboard-openapi-client";
import styled from "styled-components";

const ComponentDropdownElement = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  vertical-align: top;
  //width: 300px;
  align-items: flex-start;
`;

const ComponentDropdownElementP = styled.p`
  font-weight: var(--nhu-font-weight-bold);
  font-size: 14px;
  color: var(--freightsimple-color-normal-text);
  margin: 0;

  .selected-dropdown-row & {
    color: white;
  }
`;

const ComponentDropdownDescription = styled.p`
  font-weight: var(--nhu-font-weight-regular);
  font-size: 12px;
  color: var(--freightsimple-color-light-text);
  margin: 0;
  position: relative;
  top: -2px;

  .selected-dropdown-row & {
    color: white;
  }
`;

function getLocationTypesForDropdown() {
  function isPopular(t: LocationTypeDescription): boolean {
    switch (t.type) {
      case LocationType.Warehouse:
        return true;
      case LocationType.DistributionWarehouse:
        return true;
      case LocationType.Residential:
        return true;
      case LocationType.ConstructionSite:
        return false;
      case LocationType.School:
        return false;
      case LocationType.TradeShow:
        return false;
      case LocationType.UniversityCollege:
        return false;
      case LocationType.Farm:
        return true;
      case LocationType.Mine:
        return false;
      case LocationType.Retail:
        return false;
      case LocationType.Hospitality:
        return false;
      case LocationType.Medical:
        return false;
      case LocationType.Airport:
        return false;
      case LocationType.SelfStorage:
        return false;
      case LocationType.Port:
        return false;
      case LocationType.Prison:
        return false;
      case LocationType.OtherLimitedAccess:
        return true;
      case LocationType.ReligiousSite:
        return false;
      case LocationType.Military:
        return false;
      case LocationType.Utility:
        return false;

      default:
        assertNever(t.type);
    }
  }

  const options = [];

  const popularOptions = (locationTypes as LocationTypeDescription[]).filter(
    (o) => isPopular(o),
  );
  const otherOptions = (locationTypes as LocationTypeDescription[])
    .filter((o) => !isPopular(o))
    .sort((a, b) => a.type.localeCompare(b.type));

  function option(t: LocationTypeDescription) {
    return {
      item: t.type,
      description: (
        <DropdownElement>
          <ComponentDropdownElement>
            <ComponentDropdownElementP>{t.name}</ComponentDropdownElementP>
            <ComponentDropdownDescription>
              {t.description}
            </ComponentDropdownDescription>
          </ComponentDropdownElement>
        </DropdownElement>
      ),
    };
  }

  options.push({
    item: undefined,
    description: <span>Popular</span>,
    isHeader: true,
  });

  popularOptions.forEach((o) => options.push(option(o)));

  options.push(dropdownDivider());

  options.push({
    item: undefined,
    description: <span>Other</span>,
    isHeader: true,
  });

  otherOptions.forEach((o) => options.push(option(o)));

  return options;
}

interface LocationDropdownProps {
  locationType: LocationType;
  setLocationType: (_: LocationType) => void;
  errorMessage: ErrorMessageType;
  warningMessage: ErrorMessageType;
  infoMessage: ErrorMessageType;
  forceValidation: boolean;
}

function LocationDropdown(props: LocationDropdownProps) {
  return (
    <Dropdown
      selectedItem={props.locationType}
      onOptionSelected={props.setLocationType}
      options={getLocationTypesForDropdown()}
      width={349}
      menuWidth={349}
      errorMessage={props.errorMessage}
      warningMessage={props.warningMessage}
      infoMessage={props.infoMessage}
      forceValidation={props.forceValidation}
      // Measured this in inspector
      maxDropdownHeight={(locationTypes.length * 48.5 + 125).toFixed(0) + "px"}
    ></Dropdown>
  );
}

export default LocationDropdown;
