import { useSavedBrokersApi } from "../../../apis";
import { UUID } from "../../../Cargo/Types/types";
import {
  AddSavedBrokerOperationRequest,
  Broker,
  DeleteSavedBrokerOperationRequest,
  ModifySavedBrokerOperationRequest,
  PreferredBrokerType,
  SavedBroker,
  SetPreferredRequest,
} from "@freightsimple/generated-dashboard-openapi-client";

export function useSavedBrokersService() {
  const savedBrokersApi = useSavedBrokersApi();

  return {
    addBroker: async function (broker: SavedBroker) {
      const request: AddSavedBrokerOperationRequest = {
        addSavedBrokerRequest: {
          broker,
        },
      };

      await savedBrokersApi.addSavedBroker(request);
    },

    modifyBroker: async function (brokerId: UUID, broker: Broker) {
      const request: ModifySavedBrokerOperationRequest = {
        modifySavedBrokerRequest: {
          savedBrokerId: brokerId,
          broker: broker as Broker,
        },
      };

      await savedBrokersApi.modifySavedBroker(request);
    },

    deleteSavedBroker: async function (savedBrokerId: UUID) {
      const request: DeleteSavedBrokerOperationRequest = {
        deleteSavedBrokerRequest: {
          savedBrokerId: savedBrokerId,
        },
      };
      await savedBrokersApi.deleteSavedBroker(request);
    },

    setPreferred: async function (
      savedBrokerId: UUID,
      preferredType: PreferredBrokerType,
    ) {
      const request: SetPreferredRequest = {
        setPreferredBrokerRequest: {
          savedBrokerId,
          preferredType,
        },
      };

      await savedBrokersApi.setPreferred(request);
    },
  };
}
