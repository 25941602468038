import Button from "../../../Cargo/Controls/Button";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { Microcopy } from "../../../Cargo/Text/Text";
import { Label } from "../../../Cargo/Text/Label";
import AddressInput from "./AddressInput";
import { AddLocationLocationType } from "../Types/locationTypes";
import { errorMessagesForLocation } from "../Validators/errorMessagesForLocation";
import { useOnce } from "../../../Hooks/useOnce";
import { usePostalCodesApi } from "../../../apis";
import { Address } from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";

interface AddLocationModalAddressPage {
  location: AddLocationLocationType;
  setLocation: (_: AddLocationLocationType) => void;
  onSwitchToContactPage: () => void;
  onPrevious: () => void;
}

function AddLocationModalAddressPage(props: AddLocationModalAddressPage) {
  const { location, setLocation, onSwitchToContactPage, onPrevious } = props;
  const [forceValidation, setForceValidation] = useState(false);
  const [addressOptions, setAddressOptions] = useState<Array<string>>([]);

  const postalCode = location.address.postalCode;
  const postalCodesApi = usePostalCodesApi();

  if (!postalCode) {
    throw new Error("Missing postalCode");
  }

  function updateAddress(addressUpdates: Partial<Address>) {
    const address = {
      ...location.address,
      ...addressUpdates,
    };

    setLocation({
      ...location,
      address,
    });
  }

  useOnce(async () => {
    const _addressOptions =
      await postalCodesApi.lookUpPotentialAddresses({
        postalCode,
      });
    setAddressOptions(_addressOptions);

    if (
      _addressOptions.length === 1 &&
      (props.location.address.addressLine === "" ||
        props.location.address.addressLine === undefined)
    ) {
      updateAddress({ addressLine: _addressOptions[0] });
    }
  });

  // TODO: Make this depend on if you're on the address book screen / book shipment
  const locationErrorMessages = errorMessagesForLocation(location, false);

  function setBusinessName(businessName: string) {
    setLocation({
      ...location,
      businessName,
    });
  }

  function isValid() {
    if (locationErrorMessages.address.addressLine !== undefined) {
      return false;
    }

    if (locationErrorMessages.businessName !== undefined) {
      return false;
    }

    return true;
  }

  async function onNext() {
    if (isValid()) {
      onSwitchToContactPage();
    } else {
      setForceValidation(true);
    }
  }

  return (
    <>
      <Microcopy>Please enter the remaining parts of the address</Microcopy>
      <Stack align="left">
        <>
          <Spacer height={16} />

          <Label>Address Details</Label>
          <AddressInput
            enabled={true}
            businessName={props.location.businessName || ""}
            businessNameChanged={setBusinessName}
            locationType={props.location.locationType}
            distributionWarehouseBrand={
              props.location.distributionWarehouseBrand
            }
            address={props.location.address}
            addressChanged={updateAddress}
            forceValidation={forceValidation}
            businessNameErrorMessages={locationErrorMessages.businessName}
            addressErrorMessages={locationErrorMessages.address}
            addressOptions={addressOptions}
            allowCityAndPostalCodeEditing={false}
            warnAboutPotentialBusinessNameErrors={true}
          />
          <Spacer height={32} />
          <HorizontalStack width="100%" align="spread">
            <Button onClick={onPrevious} secondary>
              Previous
            </Button>
            <Button onClick={onNext}>Next</Button>
          </HorizontalStack>
        </>
      </Stack>
    </>
  );
}

export default AddLocationModalAddressPage;
