import Colors from "../../../Cargo/Colors";
import Link from "../../../Cargo/Controls/Link";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import { ErrorMessageType } from "../../../Cargo/Validation";
import {
  LocationContext,
  LocationType,
} from "@freightsimple/generated-dashboard-openapi-client";
import { capitalize } from "../../../Helpers/capitalize";

export function errorMessageForNotes(
  notes: string,
  accessorials: Array<string>,
  _locationType: LocationType,
  context: LocationContext,
  onModify: () => void,
): ErrorMessageType {
  function generateErrorMessage() {
    if (!accessorials.includes("LOGISTICS_LIFT_GATE_REQUIRED")) {
      if (
        notes.toLowerCase().includes("liftgate") ||
        notes.toLowerCase().includes("lift gate") ||
        notes.toLowerCase().includes("tailgate") ||
        notes.toLowerCase().includes("tail gate")
      ) {
        return "Lift Gate mentioned, but was not requested in the quote.";
      }
    }

    if (!accessorials.includes("LOGISTICS_INSIDE")) {
      if (notes.toLowerCase().includes("inside")) {
        return `Inside ${capitalize(
          context,
        )} mentioned, but was not requested in the quote.`;
      }
    }

    if (context === LocationContext.Delivery) {
      // CS: Oct 2022
      // We also sometimes have folks write something like 'call ahead', which sometimes triggers
      // 'delivery notification' accessorials with some carriers. eg. ABF. Some carriers will
      // honour it fine without charge.
      // I don't think we have a good way to explain that to the user. So we're deciding just to absorb
      // the cost of those. We can increase the rates for someone who does it consistently to cover the expense.
      if (!accessorials.includes("SCHEDULING_APPOINTMENT_REQUIRED")) {
        if (
          notes.toLowerCase().includes("appointment") ||
          notes.toLowerCase().includes("apptment") ||
          notes.toLowerCase().includes("appt")
        ) {
          return `${capitalize(context)} Appointment mentioned, but was
                        not requested in the quote.`;
        }
      }
    }

    return undefined;
  }

  const errorMessage = generateErrorMessage();

  if (errorMessage !== undefined) {
    return (
      <HorizontalStack>
        {errorMessage}
        <Spacer width={4} />
        <Link onClick={onModify}>
          <span
            style={{
              color: Colors.Red,
              textDecoration: "underline",
            }}
          >
            Modify quote
          </span>
        </Link>
      </HorizontalStack>
    );
  } else {
    return undefined;
  }
}
