import Fade from "../../../../Cargo/Animations/Fade";
import RevealBox from "../../../../Cargo/Layout/RevealBox";
import { AddLineItemType } from "../../../LineItems/Types/lineItemTypes";
import { AddLocationLocationType } from "../../../Locations/Types/locationTypes";
import {
  LineItem,
  Location,
  PreQuoteLocation,
  Quote,
} from "@freightsimple/generated-dashboard-openapi-client";
import { Currency } from "@freightsimple/generated-dashboard-openapi-client";
import moment from "moment";
import QuoteRowBody from "./QuoteRowBody";
import QuoteRowHeader from "./QuoteRowHeader";

interface QuoteRowProps {
  pickupDate: moment.Moment;
  pickupLocation: Location | AddLocationLocationType | PreQuoteLocation;
  pickupDeadline: string | undefined;
  lineItems: Array<LineItem | AddLineItemType>;
  deliveryLocation: Location | AddLocationLocationType | PreQuoteLocation;
  deliveryDeadline: string | undefined;
  quote: Quote;
  onSelectQuote: () => void;
  notes: string | undefined;
  addInsuranceToShipment: boolean;
  insuranceAmount: number;
  insuranceCurrency: Currency;
}
function QuoteRow(props: QuoteRowProps) {
  const deliveryDate = props.quote.expectedDeliveryDate
    ? moment(props.quote.expectedDeliveryDate)
    : undefined;

  return (
    <RevealBox
      header={(open) => {
        return (
          <QuoteRowHeader
            carrierIdentifier={props.quote.carrierIdentifier}
            serviceDisplayName={props.quote.serviceDisplayName}
            showSecondaryInfo={!open}
            price={props.quote.price}
            deliveryDate={deliveryDate}
            earliestTransitDays={props.quote.transitBusinessDays}
            latestTransitDays={props.quote.latestTransitBusinessDays}
            currency={props.quote.currency}
            notes={props.quote.notes}
          />
        );
      }}
      body={(open) => {
        return (
          <Fade show={open}>
            <QuoteRowBody
              pickupDeadline={props.pickupDeadline}
              pickupDate={props.pickupDate}
              pickupLocation={props.pickupLocation}
              lineItems={props.lineItems}
              deliveryLocation={props.deliveryLocation}
              deliveryDeadline={props.deliveryDeadline}
              quote={props.quote}
              onSelectQuote={props.onSelectQuote}
              notes={props.notes}
              addInsuranceToShipment={props.addInsuranceToShipment}
              insuranceAmount={props.insuranceAmount}
              insuranceCurrency={props.insuranceCurrency}
            />
          </Fade>
        );
      }}
    />
  );
}
export default QuoteRow;
