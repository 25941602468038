import { useStripe } from "@stripe/react-stripe-js";
import { usePaymentMethodsApi } from "../../../apis";
import useAlertModal from "../../../Cargo/Modal/useAlertModal";
import { useAuthentication } from "../../Authentication/Slices/authenticationSlice";
import { extractPaymentMethodId } from "../../../Shared/Utils/stripeUtils";
import { useStripeBankAdviceModal } from "./useStripeBankAdviceModal";

export function useAddNewBankAccount(
  refresh: (newPaymentMethodId: string | undefined) => Promise<void>,
) {
  const stripe = useStripe();
  const paymentMethodsApi = usePaymentMethodsApi();
  const { user } = useAuthentication();
  const alert = useAlertModal();
  const showBankAdviseModal = useStripeBankAdviceModal();

  return async function () {
    if (stripe === null) {
      console.error("stripe is null");
      return;
    }

    const response = await paymentMethodsApi.generateSaveBankAccountIntent();
    const clientSecret = response.clientSecret;

    await showBankAdviseModal();
    const result = await stripe.confirmAcssDebitSetup(clientSecret, {
      payment_method: {
        billing_details: {
          name: user?.name,
          email: user?.email,
        },
      },
    });

    if (result.error) {
      if (result.error.message !== "Please provide complete payment details.") {
        await alert("Error", result.error.message);
      }

      refresh(undefined);
    } else {
      if (result.setupIntent?.payment_method) {
        refresh(extractPaymentMethodId(result.setupIntent.payment_method));
      } else {
        refresh(undefined);
      }
    }
  };
}
