import { useSavedLocationsApi } from "../../../apis";
import { UUID } from "../../../Cargo/Types/types";
import { AddContactContactType } from "../../Contacts/Types/contactTypes";
import {
  AddDefaultContactRequest,
  AddSavedContactOperationRequest,
  AddSavedLocationOperationRequest,
  Contact,
  DeleteSavedLocationRequest,
  Location,
  ModifySavedContactOperationRequest,
  ModifySavedLocationOperationRequest,
  SavedContact,
  SavedLocation,
  SetDefaultContactOperationRequest,
  ToggleBranchRequest,
  ToggleDefaultDeliveryLocationRequest,
  ToggleDefaultPickupLocationRequest,
  ToggleFavouriteRequest,
} from "@freightsimple/generated-dashboard-openapi-client";

export function useSavedLocationsService() {
  const savedLocationsApi = useSavedLocationsApi();

  return {
    getLocation: async function (
      savedLocationId: UUID,
    ): Promise<SavedLocation> {
      return await savedLocationsApi.getOneSavedLocation({
        savedLocationId,
      });
    },

    addLocation: async function (location: SavedLocation) {
      const request: AddSavedLocationOperationRequest = {
        addSavedLocationRequest: {
          location,
        },
      };

      await savedLocationsApi.addSavedLocation(request);
    },

    modifyLocation: async function (locationId: UUID, location: Location) {
      const request: ModifySavedLocationOperationRequest = {
        modifySavedLocationRequest: {
          savedLocationId: locationId,
          location: location as Location,
        },
      };

      await savedLocationsApi.modifySavedLocation(request);
    },

    toggleIsFavourite: async function (savedLocationId: UUID) {
      const request: ToggleFavouriteRequest = {
        savedLocationId,
      };
      await savedLocationsApi.toggleFavourite(request);
    },

    toggleIsBranch: async function (savedLocationId: UUID) {
      const request: ToggleBranchRequest = {
        savedLocationId,
      };
      await savedLocationsApi.toggleBranch(request);
    },

    toggleDefaultPickupLocation: async function (savedLocationId: UUID) {
      const request: ToggleDefaultPickupLocationRequest = {
        savedLocationId,
      };
      await savedLocationsApi.toggleDefaultPickupLocation(request);
    },

    toggleDefaultDeliveryLocation: async function (savedLocationId: UUID) {
      const request: ToggleDefaultDeliveryLocationRequest = {
        savedLocationId,
      };
      await savedLocationsApi.toggleDefaultDeliveryLocation(request);
    },

    deleteSavedLocation: async function (savedLocationId: UUID) {
      const request: DeleteSavedLocationRequest = {
        deleteAddressBookEntryRequest: {
          addressBookEntryId: savedLocationId,
        },
      };
      await savedLocationsApi.deleteSavedLocation(request);
    },

    addContact: async function (contact: SavedContact) {
      const request: AddSavedContactOperationRequest = {
        addSavedContactRequest: contact,
      };

      await savedLocationsApi.addSavedContact(request);
    },

    deleteContact: async function (savedContactId: UUID) {
      await savedLocationsApi.deleteSavedContact({
        savedContactId,
      });
    },

    addDefaultContact: async function (contact: SavedContact) {
      const request: AddDefaultContactRequest = {
        addSavedContactRequest: contact,
      };

      await savedLocationsApi.addDefaultContact(request);
    },

    setDefaultContact: async function (
      savedLocationId: UUID,
      savedContactId: UUID,
    ) {
      const request: SetDefaultContactOperationRequest = {
        setDefaultContactRequest: {
          savedLocationId,
          savedContactId,
        },
      };

      await savedLocationsApi.setDefaultContact(request);
    },

    modifyContact: async function (
      savedContactId: UUID,
      contact: AddContactContactType,
    ) {
      const request: ModifySavedContactOperationRequest = {
        modifySavedContactRequest: {
          savedContactId,
          // TODO: Assert
          contact: contact as Contact,
        },
      };
      await savedLocationsApi.modifySavedContact(request);
    },
  };
}
