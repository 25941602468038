import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Colors from "../../../../Cargo/Colors";
import NumberInput from "../../../../Cargo/Controls/NumberInput";
import HorizontalStack from "../../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../../Cargo/Layout/Spacer";
import { ErrorMessageType } from "../../../../Cargo/Validation";
import {
  getPluralFormForHandlingUnitType,
  getSingularFormForHandlingUnitType,
} from "../../../../Data/HandlingUnitTypes";
import { HandlingUnitType } from "@freightsimple/generated-dashboard-openapi-client";
import { describeUnit, Dimension, Units } from "../../../../Helpers/units";

import styled from "styled-components";

const TotalWeightText = styled.span`
  font-size: 14px;
  color: ${Colors.LightText};
`;

interface DimensionsControlProps {
  length: number | undefined;
  width: number | undefined;
  height: number | undefined;
  weight: number | undefined;
  numberHandlingUnits: number | undefined;
  handlingUnitType: HandlingUnitType;

  onLengthChange: (value: number | undefined) => void;
  onWidthChange: (value: number | undefined) => void;
  onHeightChange: (value: number | undefined) => void;
  onWeightChange: (value: number | undefined) => void;

  lengthErrorMessage: ErrorMessageType;
  widthErrorMessage: ErrorMessageType;
  heightErrorMessage: ErrorMessageType;
  weightErrorMessage: ErrorMessageType;

  maxSizeWarning?: number;
  minSizeWarning?: number;

  forceValidation: boolean;

  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;

  units: Units;
}

function times() {
  return (
    <FontAwesomeIcon
      icon={["fas", "times"]}
      style={{
        width: "10px",
        height: "10px",
        marginRight: "10px",
        marginLeft: "10px",
        position: "relative",
      }}
    />
  );
}

const DimensionsControl: React.FC<DimensionsControlProps> = (
  props: DimensionsControlProps,
) => {
  const { handlingUnitType, numberHandlingUnits } = props;

  const totalWeight =
    numberHandlingUnits === undefined || props.weight === undefined
      ? undefined
      : numberHandlingUnits * props.weight;

  const hasDimensionError = [
    props.lengthErrorMessage,
    props.heightErrorMessage,
    props.widthErrorMessage,
  ].some((error) => error !== undefined);

  return (
    <div style={{ maxHeight: 81 }}>
      <HorizontalStack align="left" verticalAlign="top">
        <NumberInput
          name="contents_length"
          label={`Length (${describeUnit(props.units, Dimension.Length)})`}
          value={props.length}
          onChange={props.onLengthChange}
          width={68}
          errorMessage={props.lengthErrorMessage}
          forceValidation={props.forceValidation}
          hideErrorIcon={true}
          validateOnTimeoutMs={2000}
          onBlur={props.onBlur}
          units={props.units}
          dimension={Dimension.Length}
          maxSizeWarning={props.maxSizeWarning}
        ></NumberInput>
        {times()}
        <NumberInput
          name="contents_width"
          label={`Width (${describeUnit(props.units, Dimension.Length)})`}
          value={props.width}
          onChange={props.onWidthChange}
          width={62}
          errorMessage={props.widthErrorMessage}
          forceValidation={props.forceValidation}
          hideErrorIcon={true}
          validateOnTimeoutMs={2000}
          onBlur={props.onBlur}
          units={props.units}
          dimension={Dimension.Length}
        ></NumberInput>
        {times()}
        <NumberInput
          name="contents_height"
          label={`Height (${describeUnit(props.units, Dimension.Length)})`}
          value={props.height}
          onChange={props.onHeightChange}
          width={66}
          errorMessage={props.heightErrorMessage}
          forceValidation={props.forceValidation}
          hideErrorIcon={true}
          validateOnTimeoutMs={2000}
          onBlur={props.onBlur}
          units={props.units}
          dimension={Dimension.Length}
          minSizeWarning={props.minSizeWarning}
        ></NumberInput>
        <Spacer width={20} />
        <NumberInput
          name="contents_weight"
          label={`Weight Per ${getSingularFormForHandlingUnitType(handlingUnitType)} (${describeUnit(
            props.units,
            Dimension.Weight,
          )})`}
          value={props.weight}
          onChange={props.onWeightChange}
          width={130}
          errorMessage={props.weightErrorMessage}
          forceValidation={props.forceValidation}
          hideErrorIcon={true}
          validateOnTimeoutMs={2000}
          onBlur={props.onBlur}
          units={props.units}
          dimension={Dimension.Weight}
        ></NumberInput>
      </HorizontalStack>
      {/* The height 0 is to stop this increasing the height of the overall control and pushing other elements around */}
      <div
        style={{
          position: "relative",
          top: hasDimensionError ? "" : "-20px",
          height: "0",
        }}
      >
        {props.weightErrorMessage === undefined && totalWeight && (
          <>
            <TotalWeightText>
              Each pallet is {props.weight?.toLocaleString()}lb. Total Weight
              for {numberHandlingUnits}{" "}
              {getPluralFormForHandlingUnitType(handlingUnitType)}:{" "}
              {totalWeight.toLocaleString()}lb
            </TotalWeightText>
            <Spacer height={16} />
          </>
        )}
      </div>
    </div>
  );
};
export default DimensionsControl;
