import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Colors from "../../../Cargo/Colors";
import Input from "../../../Cargo/Controls/Input";
import LinkButton from "../../../Cargo/Controls/LinkButton";
import Box from "../../../Cargo/Layout/Box";
import Div from "../../../Cargo/Layout/Div";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import Stack from "../../../Cargo/Layout/Stack";
import { Label } from "../../../Cargo/Text/Label";
import { ErrorMessageType } from "../../../Cargo/Validation";
import {
  getPluralFormForHandlingUnitType,
  getPrepositionForHandlingUnitType,
  getSingularFormForHandlingUnitType,
} from "../../../Data/HandlingUnitTypes";
import DangerousGoodsControl from "../../DangerousGoods/Components/DangerousGoodsControl";
import { AddLineItemType } from "../Types/lineItemTypes";
import { LineItemErrors } from "../Validators/errorMessagesForLineItem";
import { Units } from "../../../Helpers/units";
import {
  HandlingUnitType,
  LineItem,
  NmfcSubItemChoice,
} from "@freightsimple/generated-dashboard-openapi-client";
import { useEffect, useRef, useState } from "react";
import scrollIntoView from "scroll-into-view-if-needed";
import styled from "styled-components";
import { useLookupNmfcCode } from "../Hooks/useLookupNmfcCode";
import { useNmfcDescription } from "../Slices/nmfcDescriptionCacheSlice";
import { generateWarningMessageForDescription } from "../Validators/generateWarningMessageForDescription";
import { ConvertUnitsButton } from "./LineItem/ConvertUnitsButton";
import DimensionsControl from "./LineItem/DimensionsControl";
import HandlingUnitsControl from "./LineItem/HandlingUnitsControl";
import IsStackableControl from "./LineItem/IsStackableControl";
import NmfcInput from "./LineItem/NmfcInput";
import DimensionsQuestionBubble from "./LineItem/QuestionBubbles/DimensionsQuestionBubble";
import HandlingUnitsQuestionBubble from "./LineItem/QuestionBubbles/HandlingUnitsQuestionBubble";
import ShipmentContentsQuestionBubble from "./LineItem/QuestionBubbles/ShipmentContentsQuestionBubble";
import TemperatureHandlingControl from "./LineItem/TemperatureHandlingControl";
import { AddLocationLocationType } from "../../Locations/Types/locationTypes";

const DifferentDimensionsText = styled.span`
  font-size: 12px;
`;

interface LineItemContainerProps {
  lineItem: AddLineItemType;

  showRemoveLineItemButton: boolean;

  onChange: (value: Partial<LineItem>) => void;
  onAddLineItem: (() => void) | undefined;
  onRemoveLineItem: (() => void) | undefined;

  lineItemErrorMessages: LineItemErrors;

  forceValidation: boolean;

  badge?: string;
  index: number;
  numberLineItems: number;

  hideBox?: boolean;

  shouldRenderFreightClass: boolean;

  setHasNmfcError: (_: boolean) => void;

  onRearrangeLengthWidth: () => void;

  units: Units;
  setUnits: (_: Units) => void;

  pickupLocation: AddLocationLocationType;
  deliveryLocation: AddLocationLocationType;
}

interface AdditionalColumnProps {
  nmfcLookupInProgress: boolean;
  nmfcDescription: string | undefined;
  nmfcErrorMessage: ErrorMessageType;
  onDimensionsChange: (value: Partial<LineItem>) => void;
  onNmfcChange: (value: Partial<LineItem>) => Promise<void>;
  clearNmfcError: () => void;
  nmfcSubItems: Array<NmfcSubItemChoice> | undefined;
}

type ColProps = LineItemContainerProps & AdditionalColumnProps;

function Col1(props: ColProps) {
  const {
    lineItem,
    lineItemErrorMessages,
    shouldRenderFreightClass,
    nmfcLookupInProgress,
    onNmfcChange,
    nmfcDescription,
  } = props;

  function onChange(value: Partial<LineItem>) {
    props.onChange(value);
  }

  const multipleHandlingUnits = lineItem.numberHandlingUnits > 1;

  function labelForContentsSection() {
    if (
      lineItem.handlingUnitType === undefined ||
      lineItem.handlingUnitType === HandlingUnitType.Other
    ) {
      return `What are the contents?`;
    }

    const preposition = getPrepositionForHandlingUnitType(
      lineItem.handlingUnitType,
    );

    if (!multipleHandlingUnits) {
      const singular = getSingularFormForHandlingUnitType(
        lineItem.handlingUnitType,
      ).toLowerCase();
      return `What is ${preposition} this ${singular}?`;
    } else {
      const plural = getPluralFormForHandlingUnitType(
        lineItem.handlingUnitType,
      );
      return `What are ${preposition} these ${plural.toLowerCase()}?`;
    }
  }

  const showDifferentDimensionText =
    multipleHandlingUnits &&
    props.onAddLineItem &&
    lineItemErrorMessages.handlingUnit === undefined;

  const warningMessageForDescription = generateWarningMessageForDescription(
    lineItem.description || "",
    props.pickupLocation,
    props.deliveryLocation,
  );
  const errorMessageForDescription =
    lineItemErrorMessages.description || warningMessageForDescription;
  const showDescriptionAsWarning =
    lineItemErrorMessages.description === undefined &&
    warningMessageForDescription !== undefined;

  const addSpacer =
    !showDifferentDimensionText &&
    lineItemErrorMessages.handlingUnit === undefined;

  return (
    <Stack align="left">
      <Label>
        Handling Units
        <HandlingUnitsQuestionBubble onAddLineItem={props.onAddLineItem} />
      </Label>

      <Spacer height={17} />
      <HandlingUnitsControl
        quantity={lineItem.numberHandlingUnits}
        onQuantityChange={(newQuantity: number) => {
          onChange({
            numberHandlingUnits: newQuantity,
          });
        }}
        handlingUnitType={lineItem.handlingUnitType}
        onHandlingUnitTypeChange={(newValue: HandlingUnitType) => {
          onChange({
            handlingUnitType: newValue,
          });
        }}
        forceValidation={true}
        errorMessage={lineItemErrorMessages.handlingUnit}
      />
      {showDifferentDimensionText && (
        <>
          <DifferentDimensionsText>
            If each{" "}
            {getSingularFormForHandlingUnitType(
              lineItem.handlingUnitType,
            ).toLowerCase()}{" "}
            has different dims{" "}
            <LinkButton regularWeight={true} onClick={props.onAddLineItem}>
              <DifferentDimensionsText>click here</DifferentDimensionsText>
            </LinkButton>{" "}
            to add another row
          </DifferentDimensionsText>
        </>
      )}

      {addSpacer && <Spacer height={21} />}

      <Spacer height={20} />
      <Label>{labelForContentsSection()}</Label>
      <Input
        label={
          <>
            Description{" "}
            <ShipmentContentsQuestionBubble
              handlingUnitType={lineItem.handlingUnitType}
              usePlural={multipleHandlingUnits}
              preposition={
                lineItem.handlingUnitType
                  ? getPrepositionForHandlingUnitType(lineItem.handlingUnitType)
                  : ""
              }
            />
          </>
        }
        type="text"
        name="contents_description"
        value={lineItem.description}
        onChange={(value) => {
          onChange({
            description: value,
          });
        }}
        width={349}
        forceValidation={props.forceValidation}
        errorMessage={errorMessageForDescription}
        placeholder="Enter Description"
        warn={showDescriptionAsWarning}
      ></Input>

      {shouldRenderFreightClass && (
        <>
          <NmfcInput
            onChange={function (newNmfcNumber: string | undefined) {
              onNmfcChange({
                ...lineItem,
                nmfcItemNumber: newNmfcNumber,
              });
            }}
            nmfcCode={lineItem.nmfcItemNumber}
            loading={nmfcLookupInProgress}
            description={nmfcDescription}
            confirmed={lineItem.nmfcItemNumber !== undefined}
            errorMessage={props.nmfcErrorMessage}
            clearError={props.clearNmfcError}
            subItems={props.nmfcSubItems}
            freightClass={lineItem.freightClass}
          />
        </>
      )}
      {!shouldRenderFreightClass && <Spacer height={81} />}
      <Spacer height={36} />
      <ConvertUnitsButton units={props.units} setUnits={props.setUnits} />
    </Stack>
  );
}

function Col2(props: ColProps) {
  const { lineItem, lineItemErrorMessages, onChange, onDimensionsChange } =
    props;
  const multipleHandlingUnits = lineItem.numberHandlingUnits > 1;

  function labelForDimensionsSection() {
    if (
      lineItem.handlingUnitType === undefined ||
      lineItem.handlingUnitType === HandlingUnitType.Other
    ) {
      return `What are the dimensions?`;
    } else {
      return `What are the dimensions for each ${lineItem.handlingUnitType
        ?.toString()
        .toLowerCase()}?`;
    }
  }

  return (
    <Stack align="left">
      <Label>
        {labelForDimensionsSection()}
        <DimensionsQuestionBubble
          handlingUnitType={lineItem.handlingUnitType}
          onAddLineItem={props.onAddLineItem}
        />
      </Label>
      <Spacer height={5} />
      <DimensionsControl
        onBlur={props.onRearrangeLengthWidth}
        length={lineItem.length}
        width={lineItem.width}
        height={lineItem.height}
        weight={lineItem.weightPerHandlingUnit}
        onLengthChange={(value) => onDimensionsChange({ length: value })}
        onWidthChange={(value) => onDimensionsChange({ width: value })}
        onHeightChange={(value) => onDimensionsChange({ height: value })}
        onWeightChange={(value) =>
          onDimensionsChange({ weightPerHandlingUnit: value })
        }
        lengthErrorMessage={lineItemErrorMessages.length}
        widthErrorMessage={lineItemErrorMessages.width}
        heightErrorMessage={lineItemErrorMessages.height}
        weightErrorMessage={lineItemErrorMessages.weight}
        forceValidation={props.forceValidation}
        numberHandlingUnits={lineItem.numberHandlingUnits}
        handlingUnitType={lineItem.handlingUnitType}
        units={props.units}
        maxSizeWarning={100}
        minSizeWarning={2}
      />

      <Spacer height={21} />

      <Label>Special Handling</Label>
      <IsStackableControl
        isStackable={lineItem.isStackable}
        onChange={(value) => onChange({ isStackable: value })}
        usePlural={multipleHandlingUnits}
        handlingUnitType={lineItem.handlingUnitType}
        errorMessage={lineItemErrorMessages.isStackable}
        forceValidation={props.forceValidation}
      />
      <Spacer height={16} />
      <TemperatureHandlingControl
        temperatureHandling={lineItem.temperatureHandling}
        onChange={(value) => onChange({ temperatureHandling: value })}
        errorMessage={lineItemErrorMessages.temperatureHandling}
        forceValidation={props.forceValidation}
      />
      <Spacer height={16} />
      <DangerousGoodsControl
        lineItem={lineItem}
        onChange={function (update) {
          onChange(update);
        }}
        errorMessage={lineItemErrorMessages.isDangerous}
        forceValidation={props.forceValidation}
      />
    </Stack>
  );
}

function LineItemContainer(props: LineItemContainerProps) {
  const PotentialBox = props.hideBox ? Div : Box;
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const { lineItem, onChange } = props;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (props.numberLineItems !== undefined && props.index !== undefined) {
        if (
          props.index > 1 &&
          props.index === props.numberLineItems &&
          ref.current
        ) {
          scrollIntoView(ref.current, {
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      }
    });
  }, [props.numberLineItems]);

  const {
    nmfcLookupInProgress,
    lookupNmfcCode,
    nmfcErrorMessage,
    clearNmfcError,
    nmfcSubItems,
  } = useLookupNmfcCode();

  const nmfcDescription = useNmfcDescription(props.lineItem.nmfcItemNumber);

  useEffect(
    function () {
      props.setHasNmfcError(nmfcErrorMessage !== undefined);
    },

    [nmfcErrorMessage],
  );

  async function onNmfcChange(value: Partial<LineItem>) {
    const newNmfcCode = value.nmfcItemNumber;
    if (newNmfcCode) {
      const response = await lookupNmfcCode({
        ...lineItem,
        ...value,
        nmfcItemNumber: newNmfcCode,
      });

      if (response) {
        onChange({
          freightClass: response.freightClass,
          nmfcItemNumber: response.nmfcWithSubItem,
        });
      } else {
        // TODO: Handle this
      }
    } else {
      onChange({
        freightClass: undefined,
        nmfcItemNumber: undefined,
      });
    }
  }

  async function onNmfcChangeDelayed(value: Partial<LineItem>) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newId = setTimeout(function () {
      onNmfcChange(value);
    }, 2000);

    setTimeoutId(newId);
  }

  function onDimensionsChange(value: Partial<LineItem>) {
    if (props.lineItem.nmfcItemNumber !== undefined) {
      onNmfcChangeDelayed({ ...props.lineItem, ...value });
    }

    onChange(value);
  }

  function onRearrangeLengthWidth() {
    const { lineItemErrorMessages } = props;
    if (
      lineItemErrorMessages.height !== undefined ||
      lineItemErrorMessages.width !== undefined ||
      lineItemErrorMessages.length !== undefined
    ) {
      // Don't rearrange when there are errors
      return;
    }

    props.onRearrangeLengthWidth();
  }

  return (
    <div ref={ref}>
      <PotentialBox badge={props.badge}>
        <Stack align="left">
          <Spacer height={32} />

          <HorizontalStack
            verticalAlign="top"
            align="spread"
            id={`lineItemContainer${props.badge ?? props.index ?? "1"}`}
          >
            <Col1
              {...props}
              nmfcLookupInProgress={nmfcLookupInProgress}
              nmfcDescription={nmfcDescription}
              nmfcErrorMessage={nmfcErrorMessage}
              onDimensionsChange={onDimensionsChange}
              onNmfcChange={onNmfcChange}
              clearNmfcError={clearNmfcError}
              nmfcSubItems={nmfcSubItems}
              onRearrangeLengthWidth={onRearrangeLengthWidth}
            />
            <Spacer width={60} />
            <Col2
              {...props}
              nmfcLookupInProgress={nmfcLookupInProgress}
              nmfcDescription={nmfcDescription}
              nmfcErrorMessage={nmfcErrorMessage}
              onDimensionsChange={onDimensionsChange}
              onNmfcChange={onNmfcChange}
              clearNmfcError={clearNmfcError}
              nmfcSubItems={nmfcSubItems}
              onRearrangeLengthWidth={onRearrangeLengthWidth}
            />
          </HorizontalStack>
          <Spacer height={24} />
          {props.showRemoveLineItemButton && (
            <>
              <Spacer height={60} />
              <HorizontalStack align="right" width="100%">
                <LinkButton onClick={props.onRemoveLineItem}>
                  <FontAwesomeIcon
                    icon={["fas", "trash"]}
                    style={{
                      width: "14px",
                      height: "14px",
                      marginRight: "5px",
                      color: Colors.Blue,
                    }}
                  />
                  Remove this
                </LinkButton>
              </HorizontalStack>
            </>
          )}
          <Spacer height={8} />
        </Stack>
      </PotentialBox>
    </div>
  );
}

export default LineItemContainer;
