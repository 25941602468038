import { ErrorMessageType } from "../../../Cargo/Validation";
import { AddLocationLocationType } from "../../Locations/Types/locationTypes";

const vagueDescriptions = [
  "A/C Parts",
  "AC Parts",
  "Accessories",
  "Aircraft Parts",
  "Advertising Material",
  "Aircraft Spare Parts",
  "Aircraft Spares",
  "Antibodies",
  "Antibody",
  "Apparel",
  "Appliance",
  "Appliances",
  "Art",
  "As Per Attached INV",
  "Assorted Merchandise",
  "Auto Part",
  "Auto Parts",
  "Automotive Parts",
  "Autoparts",
  "Bag",
  "Battery",
  "Bearing",
  "Belts",
  "Box",
  "Brake Parts",
  "Brake",
  "Business Correspondence",
  "Christmas Gifts",
  "Cable",
  "Cap",
  "Caps",
  "Carton",
  "CD",
  "CDs",
  "Chemical",
  "Chemicals",
  "Chip",
  "Christmas Gift",
  "CI Attached",
  "Cell Line",
  "Cells",
  "Civil Aircraft Parts",
  "Civil Aircraft Spares",
  "Clothes / Textiles",
  "Clothes",
  "Clothing",
  "Comat",
  "Commercial Invoice",
  "Components",
  "Connector",
  "Cosmetic Products",
  "Cosmetics",
  "Clothing and Accessories",
  "Computer Parts",
  "Computer Peripherals",
  "Culture",
  "Dangerous Good",
  "Dangerous Goods",
  "Data Processing Part",
  "Data Processing Parts",
  "Defective Goods",
  "DESC N",
  "DESCRI",
  "DG",
  "DGs",
  "Disk",
  "Disks",
  "Display",
  "DNA",
  "Doc",
  "Document",
  "Documentation",
  "Documents",
  "Dry Ice",
  "DVD",
  "DVDs",
  "Drug",
  "Drugs",
  "Electrical Parts",
  "Electronic Component",
  "Electronic Components",
  "Electronic Equipment",
  "Electronic Good",
  "Electronic Goods",
  "Electronic Part",
  "Electronic Parts",
  "Electronic",
  "Electronics",
  "Equipment",
  "Fabric Samples",
  "Fabric",
  "Fabrics",
  "FAC ",
  "FAK ",
  "Flooring",
  "Food Items",
  "Food",
  "Foodstuff",
  "Foodstuffs",
  "Freight Of All Kinds",
  "General Cargo",
  "Gift",
  "Gifts",
  "Goods",
  "Hardware",
  "Haz Mat",
  "Haz Material",
  "Haz Materials",
  "Hazardous Chemical",
  "Hazardous Chemicals",
  "Hazardous Good",
  "Hazardous Goods",
  "Hazardous Material",
  "Hazardous Materials",
  "HazMat",
  "Household Goods",
  "HS #",
  "HS NON",
  "HS#",
  "I C",
  "IC",
  "ILLEDG",
  "Illegible",
  "Implants",
  "Industrial Goods",
  "Iron",
  "Integrated Circuit",
  "Integrated Circuits",
  "Items",
  "Jeans",
  "Jewelry",
  "Letter",
  "Liquid",
  "Luggage",
  "Ladies Apparel",
  "Leather Article",
  "Leather Articles",
  "Leather",
  "Letter",
  "Liquid",
  "Luggage",
  "Machinery",
  "Machines",
  "Laboratory Reagents",
  "Machine Part",
  "Machine Parts",
  "Medical Equipment",
  "Medical Parts",
  "Medical Spare Parts",
  "Medical Supplies",
  "Medicaments",
  "Medication",
  "Medications",
  "Medicine",
  "Medicines",
  "Meds",
  "Men’s Apparel",
  "Metal Work",
  "Miscellaneous Items",
  "NAFTA",
  "New Goods",
  "No CI",
  "NO COM",
  "NO DES",
  "NON G",
  "NOT GI",
  "Pants",
  "Paper",
  "Paperwork",
  "Part",
  "Parts Of",
  "Parts",
  "Non-Hazardous",
  "Packaging Supplies",
  "PC Hardware",
  "Peripheral",
  "Personal Effects",
  "Personal Item",
  "Personal Items",
  "PIB",
  "PIBs",
  "PCB",
  "PCBA",
  "Pipe",
  "Pipes",
  "Plastic Good",
  "Plastic Goods",
  "Plastic Parts",
  "Pharmaceuticals",
  "Plastic",
  "Polyurethane",
  "Power Supply",
  "Precious Metal",
  "Printed Material",
  "Printed Materials",
  "Printed Matter",
  "Printed Matters",
  "Printed Circuit Board",
  "Promo Item",
  "Promo Items",
  "Promo Material",
  "Promo Materials",
  "Promotional Item",
  "Promotional Items",
  "Promotional Material",
  "Promotional Materials",
  "Promotional",
  "Receivers",
  "Records",
  "Report",
  "Rod",
  "Rods",
  "Rubber Articles",
  "Rubber",
  "Said To Contain",
  "Sample",
  "Samples",
  "Scrap",
  "See Attached",
  "SEE CO",
  "SEE IN",
  "See Invoice",
  "Shirt",
  "Software",
  "Spare Parts for Machine",
  "Spare Parts",
  "Spares",
  "STC",
  "Steel",
  "Surgical Instruments",
  "Swatches",
  "Tape",
  "Tapes",
  "Textile Samples",
  "Textile",
  "Textiles Samples",
  "Textiles",
  "Tile",
  "Tiles",
  "Tools",
  "Toy",
  "Tubes",
  "Wearing Apparel",
  "Training Material",
  "Training Material",
  "Training Materials",
  "Unlist",
  "Used Goods",
  "Various Goods",
  "Video Tape",
  "Video Tapes",
  "Video",
  "Videotape",
  "Videotapes",
  "VISA MDR Table",
  "Wafer",
  "Waste",
  "Wire",
  "Wires",
];

const tradeShowDescriptions = ["Trade Show", "Tradeshow"];

export function generateWarningMessageForDescription(
  description: string,
  pickupLocation: AddLocationLocationType,
  deliveryLocation: AddLocationLocationType,
): ErrorMessageType {
  const vd = vagueDescriptions.map((d) => d.toLowerCase());
  const ts = tradeShowDescriptions.map((d) => d.toLowerCase());
  if (vd.includes(description.toLowerCase())) {
    return "Description is too vague. Might cause shipping issues.";
  } else if (
    ts.some((term) => description.toLowerCase().includes(term)) &&
    pickupLocation.locationType != "TradeShow" &&
    deliveryLocation.locationType != "TradeShow"
  ) {
    return "Trade Show / Conference is not selected as pickup or delivery location.";
  } else {
    return undefined;
  }
}
