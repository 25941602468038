import { useUsersApi } from "../../../apis";

export function useRequestResetPassword() {
  const usersApi = useUsersApi();
  async function requestReset(email: string): Promise<boolean> {
    try {
      await usersApi.requestPasswordReset({
        requestPasswordResetRequest: {
          email,
        },
      });
      return true;
    } catch (e) {
      console.error("Something went wrong requesting a password reset", {
        e,
      });
      return false;
    }
  }

  return { requestReset };
}
