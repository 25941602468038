import { ErrorMessageType } from "../../../Cargo/Validation";
import { useShipmentsApi } from "../../../apis";
import {
  LineItem,
  NmfcSubItemChoice,
} from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { cacheNmfcDescription } from "../Slices/nmfcDescriptionCacheSlice";

export interface LookupNmfcCodeResponse {
  freightClass: string | undefined;
  nmfcWithSubItem: string | undefined;
}

export function useLookupNmfcCode() {
  const [nmfcLookupInProgress, setNmfcLookupInProgress] = useState(false);
  const [nmfcErrorMessage, setNmfcErrorMessage] = useState<ErrorMessageType>();
  const [nmfcSubItems, setNmfcSubItems] = useState<Array<NmfcSubItemChoice>>();
  const shipmentApi = useShipmentsApi();
  const dispatch = useDispatch();

  async function lookupNmfcCodeNoDimensions(
    nmfcItemNumber: string,
  ): Promise<boolean> {
    setNmfcLookupInProgress(true);

    const response = await shipmentApi.lookupNmfcCodeNoDimensions({
      lookupNmfcCodeNoDimensionsRequest: {
        nmfcItemNumber,
      },
    });

    setNmfcLookupInProgress(false);

    setNmfcSubItems(response.subItemChoices);
    setNmfcErrorMessage(response.errorMessage);

    if (response.success) {
      if (response.description !== undefined) {
        dispatch(
          cacheNmfcDescription({
            nmfcCode: nmfcItemNumber,
            description: response.description,
          }),
        );
      }
      return true;
    } else {
      return false;
    }
  }

  async function lookupNmfcCode(
    lineItem: Partial<LineItem>,
  ): Promise<LookupNmfcCodeResponse | undefined> {
    const { weightPerHandlingUnit, nmfcItemNumber, width, length, height } =
      lineItem;

    if (nmfcItemNumber === undefined) {
      console.error("Missing nmfcItemNumber. Should not happen");
      return;
    }

    if (
      weightPerHandlingUnit === undefined ||
      width === undefined ||
      length === undefined ||
      height === undefined
    ) {
      // We don't have full dimensions to the best we can do is look up without dimensions for now
      // and relookup when they enter the dimensions later
      const response = await shipmentApi.lookupNmfcCodeNoDimensions({
        lookupNmfcCodeNoDimensionsRequest: {
          nmfcItemNumber,
        },
      });

      setNmfcLookupInProgress(false);

      setNmfcSubItems(response.subItemChoices);
      setNmfcErrorMessage(response.errorMessage);

      if (response.success) {
        if (response.description !== undefined) {
          dispatch(
            cacheNmfcDescription({
              nmfcCode: nmfcItemNumber,
              description: response.description,
            }),
          );
        }
        return {
          nmfcWithSubItem: nmfcItemNumber,
          freightClass: undefined,
        };
      } else {
        return { nmfcWithSubItem: undefined, freightClass: undefined };
      }
      return;
    }

    setNmfcLookupInProgress(true);

    const response = await shipmentApi.lookupNmfcCodeForLineItem({
      lookupNmfcCodeForLineItemRequest: {
        weightPerHandlingUnit,
        nmfcItemNumber,
        width,
        height,
        length,
      },
    });

    setNmfcLookupInProgress(false);

    if (response.success) {
      if (response.description !== undefined) {
        dispatch(
          cacheNmfcDescription({
            nmfcCode: nmfcItemNumber,
            description: response.description,
          }),
        );
      }
    }

    setNmfcSubItems(response.subItemChoices);
    setNmfcErrorMessage(response.errorMessage);

    return {
      freightClass: response.freightClass,
      nmfcWithSubItem: response.nmfcWithSubItem,
    };
  }

  function clearNmfcError() {
    setNmfcErrorMessage(undefined);
  }

  return {
    nmfcLookupInProgress,
    lookupNmfcCode,
    lookupNmfcCodeNoDimensions,
    nmfcErrorMessage,
    nmfcSubItems,
    clearNmfcError,
  };
}
