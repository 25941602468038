import Colors from "../../../Cargo/Colors";
import Banner from "../../../Cargo/Controls/Banner";
import { BannerStyle } from "../../../Cargo/Controls/BannerStyle";
import {
  MicroErrorQuestionBubble,
  MicroWarningQuestionBubble,
  QuestionBubbleLabel,
} from "../../../Cargo/Controls/QuestionBubble";
import ThreeDotMenu, {
  ThreeDotMenuItem,
} from "../../../Cargo/Controls/ThreeDotMenu";
import Tooltip from "../../../Cargo/Controls/Tooltip";
import Icon from "../../../Cargo/Icons/Icon";
import LargeCreditCardLogo from "../../../Cargo/Icons/LargeCreditCardLogo";
import YellowExclamationAnimatedIcon from "../../../Cargo/Icons/YellowExclamationAnimatedIcon";
import HorizontalStack from "../../../Cargo/Layout/HorizontalStack";
import Spacer from "../../../Cargo/Layout/Spacer";
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "../../../Cargo/Layout/Table";
import {
  BankAccount,
  CreditCard,
} from "@freightsimple/generated-dashboard-openapi-client";
import styled from "styled-components";

interface CreditCardsSectionProps {
  creditCards: Array<CreditCard>;
  bankAccounts: Array<BankAccount>;
  setDefaultCard: (stripePaymentMethodId: string) => void;
  removeCard: (stripePaymentMethodId: string) => void;
  allowRemoveCardButton: boolean;
}

const DefaultPill = styled.div`
  padding: 4px 16px;
  background-color: ${Colors.DarkGreen};
  border-radius: 4px;
  width: 90px;
`;

const DefaultLabel = styled.div`
  color: ${Colors.White};
  font-size: 12px;
  font-weight: var(--nhu-font-weight-medium);
`;

const AccountNumberLabel = styled.div`
  color: var(--freightsimple-color-light-text);
  font-size: 16px;
  font-weight: var(--nhu-font-weight-light);
`;

function CreditCardsSection(props: CreditCardsSectionProps) {
  const {
    creditCards,
    bankAccounts,
    setDefaultCard,
    removeCard,
    allowRemoveCardButton,
  } = props;

  function itemsForPaymentMethod(creditCard: CreditCard) {
    const { stripePaymentMethodId } = creditCard;
    const items: Array<ThreeDotMenuItem> = [];

    items.push({
      name: "Make Default",
      action: function () {
        setDefaultCard(stripePaymentMethodId);
      },
      disabled: creditCard.isDefault,
      disabledExplanation: "This is already default",
    });

    items.push({
      name: "Remove Credit Card",
      action: function () {
        removeCard(stripePaymentMethodId);
      },
      disabled: !allowRemoveCardButton,
      disabledExplanation: "Cannot remove last payment method",
    });

    return items;
  }

  const defaultCreditCard = creditCards.some(function (cc) {
    return cc.isDefault;
  });
  const defaultBankAccount = bankAccounts.some(function (ba) {
    return ba.isDefault;
  });
  const showBanner = !defaultCreditCard && defaultBankAccount;

  const CardExpiry = ({ expiry }: { expiry: string }) => {
    const [month, year] = expiry.split("/").map(Number);
    const expiryDate = new Date(year, month, 0);
    const threeMonthsFromNow = new Date();
    threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 3);

    const isExpired = expiryDate < new Date();

    if (isExpired) {
      return (
        <HorizontalStack align="center" verticalAlign="middle">
          <span style={{ color: Colors.Red, marginBottom: -5, marginRight: 2 }}>
            {expiry}
          </span>
          <MicroErrorQuestionBubble
            description={
              <div style={{ width: "300px" }}>
                <HorizontalStack>
                  <strong>Card Expired</strong>
                </HorizontalStack>
                <Spacer height={8} />
                <p>This card has expired. Please update your payment method.</p>
              </div>
            }
          />
        </HorizontalStack>
      );
    }

    if (expiryDate <= threeMonthsFromNow) {
      return (
        <HorizontalStack>
          <AccountNumberLabel>{expiry}</AccountNumberLabel>

          <Spacer width={4} />
          <div style={{ marginBottom: 3 }}>
            <MicroWarningQuestionBubble
              description={
                <div style={{ width: "300px" }}>
                  <HorizontalStack>
                    <YellowExclamationAnimatedIcon
                      style={{ height: 20, width: 20, marginBottom: 5 }}
                    />
                    <Spacer width={5} />
                    <strong>Expiring Soon</strong>
                  </HorizontalStack>
                  <Spacer height={8} />
                  <p>
                    This card will expire soon. Please update your payment
                    method.
                  </p>
                </div>
              }
            />
          </div>
        </HorizontalStack>
      );
    }

    return <AccountNumberLabel>{expiry}</AccountNumberLabel>;
  };

  return (
    <>
      {showBanner && (
        <>
          <Spacer height={16} />
          <Banner
            bannerStyle={BannerStyle.Warning}
            message="Your current default payment method is set to a bank account"
          />
        </>
      )}
      <Spacer height={16} />
      <Table>
        <TableHeaderRow>
          <TableHeaderCell style={{ width: "200px" }}>
            Card Type
          </TableHeaderCell>
          <TableHeaderCell style={{ width: "160px" }}>
            Cardholder
          </TableHeaderCell>
          <TableHeaderCell style={{ width: "100px" }}>Expiry</TableHeaderCell>

          <TableHeaderCell style={{ width: "120px" }}>Last 4</TableHeaderCell>
          <TableHeaderCell style={{ width: "80px" }}></TableHeaderCell>
          <TableHeaderCell style={{ width: "100px" }}></TableHeaderCell>
        </TableHeaderRow>
        {creditCards.map((creditCard, index) => {
          return (
            <TableRow key={index}>
              <TableCell>
                <HorizontalStack verticalAlign="middle">
                  <LargeCreditCardLogo brand={creditCard.brand} />
                </HorizontalStack>
              </TableCell>
              <TableCell>
                <AccountNumberLabel>{creditCard.name}</AccountNumberLabel>
              </TableCell>
              <TableCell>
                <HorizontalStack verticalAlign="middle">
                  <CardExpiry expiry={creditCard.expiry} />
                </HorizontalStack>
              </TableCell>

              <TableCell>
                <AccountNumberLabel>
                  Ending {creditCard.lastFourDigits}
                </AccountNumberLabel>
              </TableCell>
              <TableCell>
                {creditCard.isDefault && (
                  <Tooltip
                    tooltip={
                      <div style={{ padding: "10px" }}>
                        <QuestionBubbleLabel>
                          This is the default payment method that will be used
                          for shipments.
                        </QuestionBubbleLabel>
                      </div>
                    }
                  >
                    <DefaultPill>
                      <HorizontalStack align="spread">
                        <Icon
                          name="check"
                          color={Colors.White}
                          size={14}
                          solid
                        />
                        <Spacer width={4} />
                        <DefaultLabel>Default</DefaultLabel>
                      </HorizontalStack>
                    </DefaultPill>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell>
                <HorizontalStack align="right" style={{ paddingRight: "40px" }}>
                  <ThreeDotMenu
                    color={Colors.MidGray}
                    items={itemsForPaymentMethod(creditCard)}
                  />
                </HorizontalStack>
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
    </>
  );
}
export default CreditCardsSection;
