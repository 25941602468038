import { Page } from "../../Cargo/Layout/Page";
import { APIAccessScreen } from "../APIAccess/APIAccessScreen";
import BankAccountsScreen from "../BankAccounts/BankAccountsScreen";
import ShipmentBookedScreen from "../BookShipment/ShipmentBookedScreen";
import BrokersScreen from "../Brokers/BrokersScreen";
import { SubmitClaimScreen } from "../Claims/SubmitClaimScreen";
import CreditCardsScreen from "../CreditCards/CreditCardsScreen";
import ViewInvoicesScreen from "../Invoices/ViewInvoicesScreen";
import AddressBookScreen from "../Locations/AddressBookScreen";
import PaymentsScreen from "../Payments/PaymentsScreen";
import { SavedCommoditiesScreen } from "../SavedCommodities/SavedCommoditiesScreen";
import ViewCompanyScreen from "../ViewCompany/ViewCompanyScreen";
import ViewAllShipmentsScreen from "../ViewShipments/ViewAllShipmentsScreen";
import ViewShipmentScreen from "../ViewShipments/ViewShipmentScreen";
import DashboardScreen from "./DashboardScreen";
import QuotesScreen from "./QuotesScreen";

function DashboardRouter() {
  return (
    <>
      <Page exact path="/">
        {() => <DashboardScreen />}
      </Page>

      <Page exact path="/quotes">
        {() => <QuotesScreen />}
      </Page>

      <Page exact path="/view-all-shipments">
        {() => <ViewAllShipmentsScreen />}
      </Page>

      <Page exact path="/address-book">
        {() => <AddressBookScreen />}
      </Page>
      <Page exact path="/brokers">
        {() => <BrokersScreen />}
      </Page>

      <Page exact path="/saved-commodities">
        {() => <SavedCommoditiesScreen />}
      </Page>

      <Page exact path="/credit-cards">
        {() => <CreditCardsScreen />}
      </Page>

      <Page exact path="/payments">
        {() => <PaymentsScreen />}
      </Page>

      <Page exact path="/bank-accounts">
        {() => <BankAccountsScreen />}
      </Page>

      <Page exact path="/manage-users">
        {() => <ViewCompanyScreen />}
      </Page>

      <Page exact path="/api-access">
        {() => <APIAccessScreen />}
      </Page>

      <Page exact path="/view-invoices">
        {() => <ViewInvoicesScreen />}
      </Page>

      <Page exact path="/view-shipment">
        {() => <ViewShipmentScreen />}
      </Page>

      <Page exact path="/submit-claim">
        {() => <SubmitClaimScreen />}
      </Page>

      <Page exact path="/booking-success">
        {() => <ShipmentBookedScreen />}
      </Page>
    </>
  );
}
export default DashboardRouter;
