import Colors from "../../Cargo/Colors";
import Banner, { BannerBody, BannerHeader } from "../../Cargo/Controls/Banner";
import { BannerStyle } from "../../Cargo/Controls/BannerStyle";
import Button from "../../Cargo/Controls/Button";
import Switch from "../../Cargo/Controls/Switch";
import FullWidthLayout from "../../Cargo/Layout/FullWidthLayout";
import HorizontalStack from "../../Cargo/Layout/HorizontalStack";
import Spacer from "../../Cargo/Layout/Spacer";
import Stack from "../../Cargo/Layout/Stack";
import PageTitle from "../../Cargo/Text/PageTitle";
import { Heading1, Legalese, Microcopy } from "../../Cargo/Text/Text";
import LoadingShipment from "../BookShipment/Components/LoadingShipment";
import { generateCreditBannerMessage } from "../Invoices/Components/generateCreditBannerMessage";
import InvoiceRow from "../Invoices/Components/InvoiceRow";
import { useInvoicesStats } from "../Invoices/Hooks/useInvoicesStats";
import { GetQuotesForFirstShipmentButton } from "../ViewShipments/ViewAllShipmentsScreen";
import { sumOf } from "../../Helpers/sumOf";
import { useOnce } from "../../Hooks/useOnce";
import { useShipmentService } from "../../Services/ShipmentService";
import { useInvoicesApi } from "../../apis";
import {
  InvoiceState,
  Shipment,
} from "@freightsimple/generated-dashboard-openapi-client";
import { Invoice } from "@freightsimple/generated-dashboard-openapi-client";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelectPaymentMethodAndConfirmPaymentModal } from "./Modals/useSelectPaymentMethodAndConfirmPaymentModal";

interface PayInvoicesButtonProps {
  numberSelectedInvoices: number;
  onClick: () => void;
}

function PayInvoicesButton(props: PayInvoicesButtonProps) {
  if (props.numberSelectedInvoices > 0) {
    return (
      <Button onClick={props.onClick} arrow="right">
        Next, select payment method
      </Button>
    );
  } else {
    return <Button disabled>Pay Invoices</Button>;
  }
}

function PaymentsScreen() {
  const shipmentsService = useShipmentService();
  const [invoices, setInvoices] = useState<Array<Invoice>>([]);
  const invoicesStats = useInvoicesStats();

  const showPaymentModal = useSelectPaymentMethodAndConfirmPaymentModal();

  const [loading, setLoading] = useState(true);
  const [bookedShipments, setBookedShipments] = useState<Array<Shipment>>([]);
  const invoicesApi = useInvoicesApi();
  const navigate = useNavigate();
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState<string[]>([]);

  async function loadInvoices() {
    const response = await invoicesApi.listInvoices();
    setInvoices(response.invoices.filter(takeIssuedInvoice));
    setSelectedInvoiceIds(
      response.invoices
        .filter(takeIssuedInvoice)
        .map((invoice) => invoice.invoiceId),
    );
  }

  async function refresh() {
    setLoading(true);
    await loadInvoices();
    await loadShipments();
    setLoading(false);
  }

  useOnce(refresh);

  function takeIssuedInvoice(invoice: Invoice) {
    return invoice.invoiceState === InvoiceState.Issued;
  }

  const nothingToShow = invoices.length === 0;

  async function loadShipments() {
    const response = await shipmentsService.getShipments();

    setBookedShipments(response.booked);
  }

  if (loading || invoicesStats === undefined) {
    return <LoadingShipment />;
  }

  function selectInvoice(invoiceId: string) {
    setSelectedInvoiceIds([...selectedInvoiceIds, invoiceId]);
  }

  function deselectInvoice(invoiceId: string) {
    setSelectedInvoiceIds(
      selectedInvoiceIds.filter((_invoiceId) => _invoiceId !== invoiceId),
    );
  }

  function bannerMessage() {
    const selectedInvoices = invoices.filter(function (invoice) {
      return selectedInvoiceIds.includes(invoice.invoiceId);
    });
    const invoicesTotal = sumOf(selectedInvoices, (invoice) => invoice.amount);

    if (selectedInvoiceIds.length === 0) {
      return `No invoices selected to pay`;
    } else {
      const currency = selectedInvoices[0].currency;
      return `You have selected ${
        selectedInvoiceIds.length
      } invoices to pay. Total $${invoicesTotal.toFixed(2)} ${currency}`;
    }
  }

  const selectedInvoices = invoices.filter((invoice) =>
    selectedInvoiceIds.includes(invoice.invoiceId),
  );

  return (
    <>
      <PageTitle>Payments</PageTitle>
      <FullWidthLayout
        header="Payments"
        microcopy={generateCreditBannerMessage(
          invoicesStats.paymentTermsDays,
          invoicesStats.creditAmount,
          invoicesStats.creditCurrency,
          invoicesStats.issuedInvoicesTotal,
        )}
        showEmptyState={bookedShipments.length === 0 || invoices.length === 0}
        emptyState={
          <>
            {bookedShipments.length === 0 && (
              <Stack>
                <Heading1>You don&apos;t have any invoices to pay yet</Heading1>
                <Microcopy>
                  Once you have booked shipments, you will be able to quickly
                  pay invoices here.
                </Microcopy>
                <Spacer height={32} />
                <GetQuotesForFirstShipmentButton />
              </Stack>
            )}
            {bookedShipments.length !== 0 && invoices.length === 0 && (
              <Stack>
                <Heading1>You don&apos;t have any unpaid invoices</Heading1>
                <Microcopy>
                  You will be able to pay any outstanding invoices here
                </Microcopy>
              </Stack>
            )}
          </>
        }
        loading={loading}
        content={
          <>
            {nothingToShow && (
              <Legalese>
                There are no invoices to pay. Please contact our customer
                support team if you believe this is an error.
              </Legalese>
            )}
            {!nothingToShow && (
              <>
                <Banner
                  bannerStyle={
                    selectedInvoiceIds.length === 0
                      ? BannerStyle.Neutral
                      : BannerStyle.Info
                  }
                  message={
                    <Stack align="left" style={{ marginLeft: "8px" }}>
                      <BannerHeader>{bannerMessage()}</BannerHeader>
                      <BannerBody></BannerBody>
                    </Stack>
                  }
                  right={
                    <PayInvoicesButton
                      numberSelectedInvoices={selectedInvoiceIds.length}
                      onClick={async function () {
                        await showPaymentModal(selectedInvoices);
                        await refresh();
                      }}
                    />
                  }
                  hideIcon
                />
                <>
                  <Spacer height={32} />
                  <Microcopy>
                    Select the invoices that you would like to include in this
                    payment
                  </Microcopy>
                  <Spacer height={16} />
                </>
                {invoices.map(function (invoice) {
                  const shipment = bookedShipments.find(function (s) {
                    return s.shipmentId === invoice.shipmentId;
                  });

                  if (shipment === undefined) {
                    console.error("Shipment not found");
                    return <></>;
                  }

                  const shipmentId = shipment.shipmentId;

                  function onViewShipment() {
                    const url = `/view-shipment?shipmentId=${shipmentId}`;
                    navigate(url);
                  }

                  function onDownloadInvoice() {
                    shipmentsService.downloadInvoice(
                      shipmentId,
                      invoice.invoiceIdentifier,
                      invoice.invoicePdfKey,
                    );
                  }

                  const includedInPayment = selectedInvoiceIds.includes(
                    invoice.invoiceId,
                  );

                  return (
                    <HorizontalStack
                      verticalAlign="middle"
                      key={invoice.invoiceId}
                    >
                      <div
                        style={{
                          position: "relative",
                          top: "-8px",
                          marginRight: "8px",
                        }}
                      >
                        <HorizontalStack>
                          <div
                            style={{
                              width: "70px",
                              color: Colors.LightText,
                              fontSize: "14px",
                              position: "relative",
                              top: "-2px",
                            }}
                          >
                            {includedInPayment ? `Include` : `Do not pay`}
                          </div>
                          <Switch
                            on={includedInPayment}
                            onChange={function (enabled) {
                              if (enabled) {
                                selectInvoice(invoice.invoiceId);
                              } else {
                                deselectInvoice(invoice.invoiceId);
                              }
                            }}
                          />
                        </HorizontalStack>
                      </div>
                      <InvoiceRow
                        key={invoice.invoiceIdentifier}
                        invoice={invoice}
                        shipment={shipment}
                        onViewShipment={onViewShipment}
                        onDownloadInvoice={onDownloadInvoice}
                        highlightBorder={includedInPayment}
                        downloadButtonNonPrimary
                      />
                    </HorizontalStack>
                  );
                })}
              </>
            )}
          </>
        }
      />
    </>
  );
}

export default PaymentsScreen;
